import { useNotifyOpenLayerObserver } from '@atlaskit/layering/experimental/open-layer-observer';

type NotifyOpenLayerObserverProps = Parameters<typeof useNotifyOpenLayerObserver>[0];

/**
 * Functional component wrapper around `useNotifyOpenLayerObserver`.
 *
 * This is needed as Select is a class component, which cannot use hooks directly.
 */
export function NotifyOpenLayerObserver({ isOpen, onClose }: NotifyOpenLayerObserverProps): null {
	useNotifyOpenLayerObserver({ isOpen, onClose });

	return null;
}
