import TextBasic from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-basic";
import TextColorInverse from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-color-inverse";
import TextColorInherit from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-color-inherit";
import TextWeight from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-weight";
import TextAlign from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-align";
import TextAsElement from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-as-element";
import TextSpacing from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-spacing";
import TextTruncation from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-truncation";
import TextCustomization from "../../../../../../packages/design-system/primitives/examples/constellation/text/text-customization";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_basic from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-basic";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_color_inverse from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-color-inverse";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_color_inherit from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-color-inherit";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_weight from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-weight";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_align from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-align";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_as_element from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-as-element";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_spacing from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-spacing";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_truncation from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-truncation";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_customization from "!!raw-loader!../../../../../../packages/design-system/primitives/examples/constellation/text/text-customization";
import * as React from 'react';
export default {
  TextBasic,
  TextColorInverse,
  TextColorInherit,
  TextWeight,
  TextAlign,
  TextAsElement,
  TextSpacing,
  TextTruncation,
  TextCustomization,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_basic,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_color_inverse,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_color_inherit,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_weight,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_align,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_as_element,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_spacing,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_truncation,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHtextSLASHtext_customization,
  React
};