/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { Footer, FooterColumn, FooterRowItem } from '@af/design-system-docs-ui';

const styles = css({ gridArea: 'footer' });

const columns: FooterColumn[] = [
	{
		title: "What's new",
		items: [
			{
				label: 'Featured',
				href: '/whats-new#featured',
			},
			{
				label: '2025',
				href: '/whats-new#2025',
			},
		],
	},
	{
		title: 'Get started',
		items: [
			{
				label: 'Design',
				href: '/get-started/design',
			},
			{
				label: 'Develop',
				href: '/get-started/develop',
			},
			{
				label: 'Content design',
				href: '/get-started/content-design',
			},
			{
				label: 'Figma libraries',
				href: '/get-started/figma-libraries',
			},
			{
				label: 'Vision, values, and principles',
				href: '/get-started/vision-values-principles',
			},
			{
				label: 'Release phases',
				href: '/get-started/release-phases',
			},
		],
	},
	{
		title: 'Foundations',
		items: [
			{
				label: 'Tokens',
				href: '/foundations/tokens',
			},
			{
				label: 'Accessibility',
				href: '/foundations/accessibility',
			},
			{
				label: 'Content',
				href: '/foundations/content',
			},
			{
				label: 'Spacing',
				href: '/foundations/spacing',
			},
			{
				label: 'Grid',
				href: '/foundations/grid',
			},
			{
				label: 'Color',
				href: '/foundations/color-new',
			},
			{
				label: 'Typography',
				href: '/foundations/typography',
			},
			{
				label: 'Iconography',
				href: '/foundations/iconography',
			},
			{
				label: 'Illustrations',
				href: '/foundations/illustrations',
			},
			{
				label: 'Logos',
				href: '/foundations/logos',
			},
			{
				label: 'Elevation',
				href: '/foundations/elevation',
			},
		],
	},
	{
		title: 'Components',
		items: [
			{
				label: 'Forms and input',
				href: '/components#forms-and-inputs',
			},
			{
				label: 'Images and icons',
				href: '/components#images-and-icons',
			},
			{
				label: 'Layout and structure',
				href: '/components#layout-and-structure',
			},
			{
				label: 'Loading',
				href: '/components#loading',
			},
			{
				label: 'Messaging',
				href: '/components#messaging',
			},
			{
				label: 'Navigation',
				href: '/components#navigation',
			},
			{
				label: 'Overlays and layering',
				href: '/components#overlays-and-layering',
			},
			{
				label: 'Status indicators',
				href: '/components#status-indicators',
			},
			{
				label: 'Text and data display',
				href: '/components#text-and-data-display',
			},
			{
				label: 'Primitives',
				href: '/components#primitives',
			},
			{
				label: 'Libraries',
				href: '/components#libraries',
			},
		],
	},
	{
		title: 'Tools',
		items: [
			{
				label: 'Figma libraries',
				href: '/get-started/figma-libraries',
			},
			{
				label: 'App provider',
				href: '/components/app-provider',
			},
			{
				label: 'ESLint plugin',
				href: '/components/eslint-plugin-design-system',
			},
			{
				label: 'Storybook addon',
				href: '/components/storybook-addon-design-system',
			},
			{
				label: 'Stylelint plugin',
				href: '/components/stylelint-design-system',
			},
			{
				label: 'UI Styling Standard',
				href: '/components/eslint-plugin-ui-styling-standard',
			},
		],
	},
];

const row: FooterRowItem[] = [
	{
		label: 'Contact us',
		href: '/contact-us',
	},
	{
		label: 'Work Life blog',
		href: 'https://www.atlassian.com/blog',
	},
	{
		label: 'Careers',
		href: 'https://www.atlassian.com/company/careers',
	},
	{
		label: 'Trademark',
		href: 'https://www.atlassian.com/legal/trademark',
	},
	{
		label: 'Privacy',
		href: 'https://www.atlassian.com/legal/privacy-policy',
	},
	{
		label: 'License',
		href: '/license',
	},
];

export default () => (
	<div css={styles}>
		<Footer columns={columns} row={row} />
	</div>
);
