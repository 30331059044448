import SelectSingle from "../../../../../../packages/design-system/select/examples/constellation/select-single";
import SelectSingleLegacy from "../../../../../../packages/design-system/select/examples/constellation/select-single-legacy";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHselect_single from "!!raw-loader!../../../../../../packages/design-system/select/examples/constellation/select-single";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHselect_single_legacy from "!!raw-loader!../../../../../../packages/design-system/select/examples/constellation/select-single-legacy";
import * as React from 'react';
export default {
  SelectSingle,
  SelectSingleLegacy,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHselect_single,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHselect_single_legacy,
  React
};