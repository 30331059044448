/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type PropsWithChildren, type ReactNode } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';

import { HeadingIcon } from '@af/design-system-docs-ui';
import { CustomItem, type CustomItemProps } from '@atlaskit/menu';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { Indices } from './client';

type HeadingItemProps = PropsWithChildren<{
	indexName: Indices;
}>;

const backgroundMapping = {
	Components: token('color.background.accent.green.subtlest', '#DFFCF0'),
	Tokens: token('color.background.accent.yellow.subtlest', '#FFF7D6'),
	Foundations: token('color.background.accent.teal.subtlest', '#E3FAFC'),
	Content: token('color.background.accent.red.subtlest', '#FFEDEB'),
	'Get started': token('color.background.accent.purple.subtlest', '#F3F0FF'),
};

const headingItemStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	paddingBlock: 'space.150',
	paddingInline: 'space.150',
	marginBlock: 'space.0',
	marginInline: 'space.200',
	borderRadius: 'border.radius.200',
	gap: 'space.100',
});

/**
 * __Heading item__
 */
export const HeadingItem = ({ children, indexName }: HeadingItemProps) => (
	<CustomItem
		component={({ children }) => (
			<Box
				xcss={headingItemStyles}
				style={{
					backgroundColor: backgroundMapping[indexName],
				}}
			>
				<HeadingIcon heading={indexName} />
				{children}
			</Box>
		)}
	>
		{children}
	</CustomItem>
);

interface LinkItemProps extends CustomItemProps {
	to: string;
	children: ReactNode;
}

const linkItemStyles = xcss({
	display: 'block',
	paddingBlock: 'space.200',
	paddingInline: 'space.300',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'none',
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

const linkItemContentStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	em: {
		fontStyle: 'inherit',
		fontWeight: token('font.weight.bold', '700'),
	},
});

/**
 * __Link item__
 */
export const LinkItem = ({ to, children, ...props }: LinkItemProps) => (
	<CustomItem
		{...props}
		component={({ children, ...rest }) => (
			<Anchor href={to} xcss={linkItemStyles} {...rest}>
				<div css={linkItemContentStyles}>{children}</div>
			</Anchor>
		)}
	>
		{children}
	</CustomItem>
);
