import React from 'react';

import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';

import { TypographyTable } from '../index';

const headingValuesLegacy = [
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xxlarge',
		fontSize: '35px',
		lineHeight: '40px',
		fontWeight: 500,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xlarge',
		fontSize: '29px',
		lineHeight: '32px',
		fontWeight: 600,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.large',
		fontSize: '24px',
		lineHeight: '28px',
		fontWeight: 500,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.medium',
		fontSize: '20px',
		lineHeight: '24px',
		fontWeight: 500,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.small',
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 600,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xsmall',
		fontSize: '14px',
		lineHeight: '16px',
		fontWeight: 600,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xxsmall',
		fontSize: '12px',
		lineHeight: '16px',
		fontWeight: 600,
	},
];

const headingValuesModernized = [
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xxlarge',
		fontSize: '32px',
		lineHeight: '36px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xlarge',
		fontSize: '28px',
		lineHeight: '32px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.large',
		fontSize: '24px',
		lineHeight: '28px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.medium',
		fontSize: '20px',
		lineHeight: '24px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.small',
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xsmall',
		fontSize: '14px',
		lineHeight: '20px',
		fontWeight: 653,
	},
	{
		previewText: 'Aa',
		tokenName: 'font.heading.xxsmall',
		fontSize: '12px',
		lineHeight: '16px',
		fontWeight: 653,
	},
];

/**
 * __Typography heading table__
 */
export const TypographyHeadingTable = () => {
	return (
		<Tabs id="typography-heading-table-container">
			<TabList>
				<Tab>Refreshed/Modernized</Tab>
				<Tab>Legacy</Tab>
			</TabList>
			<TabPanel>
				<TypographyTable
					values={headingValuesModernized}
					typographyTheme="typography-modernized"
					testId="typography-heading-table-modernized"
				/>
			</TabPanel>
			<TabPanel>
				<TypographyTable
					values={headingValuesLegacy}
					typographyTheme="typography-adg3"
					testId="typography-heading-table-legacy"
				/>
			</TabPanel>
		</Tabs>
	);
};
