import React, { useCallback, useState } from 'react';

import { MDXProvider } from '@mdx-js/react';
import { useLocation } from '@reach/router';

import {
	DoDont,
	type DoDontProps,
	Example,
	SideNavigation,
	TopNavigation,
} from '@af/design-system-docs-ui';
import AppProvider from '@atlaskit/app-provider';
import { Code as Snippet } from '@atlaskit/code';
import { Main, Root as PageLayout } from '@atlassian/navigation-system';

import MetaTags from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/meta-tags';
import Props from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/components/props';
import PropsLogger from '../__DO_NOT_ADD_TO_THIS_FOLDER__/gatsby-theme-brisk/utilities/props-logger';
import useSideNavigation from '../hooks/use-side-navigation';

import AppProviderRouterLink from './app-provider-router-link';
import Footer from './footer';
import GlobalStyles from './global-styles';
import SearchModal from './search';
import Settings from './settings';

type LayoutProps = {
	children: any;
};

const DoDontWrapper = ({ fullWidth, ...other }: DoDontProps & { fullWidth?: boolean }) => {
	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={{ display: 'grid', gridColumn: `span ${fullWidth ? '12' : '6'}` }}
		>
			<DoDont {...other} isFullWidth />
		</div>
	);
};

const shortcodes = {
	DoDont: DoDontWrapper,
	Example,
	PropsLogger,
	PropsTable: Props,
	Snippet,
} as const;

/**
 * __Layout__
 *
 * A layout for design system docs.
 *
 */
const Layout = ({ children }: LayoutProps) => {
	const navigation = useSideNavigation();
	const { pathname: currentPath } = useLocation();

	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const handleSearchClose = useCallback(() => {
		setIsSearchOpen(false);
	}, []);
	const handleSearchOpen = useCallback(() => {
		setIsSearchOpen(true);
	}, []);

	return (
		<MDXProvider components={shortcodes}>
			<AppProvider
				defaultColorMode="light"
				defaultTheme={{
					dark: 'dark',
					light: 'light',
					shape: 'shape',
					spacing: 'spacing',
					typography: 'typography-refreshed',
				}}
				routerLinkComponent={AppProviderRouterLink}
			>
				<GlobalStyles />
				<MetaTags isGlobal />
				<PageLayout>
					<TopNavigation
						id="top-navigation"
						navigation={navigation}
						onSearchOpen={handleSearchOpen}
						ThemeSwitcher={<Settings />}
					/>
					<SideNavigation id="side-navigation" navigation={navigation} currentPath={currentPath} />

					<Main id="main-content" isFixed>
						{children}
						<Footer />
					</Main>
				</PageLayout>
				<SearchModal isOpen={isSearchOpen} onClose={handleSearchClose} />
			</AppProvider>
		</MDXProvider>
	);
};

export default Layout;
