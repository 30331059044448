/* eslint-disable @repo/internal/react/require-jsdoc */
import React from 'react';

import type { AllSearchResults, BasicDoc, StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';

import { LinkButton } from '@atlaskit/button/new';
import EmptyState from '@atlaskit/empty-state';
import Heading from '@atlaskit/heading';
import { Section } from '@atlaskit/menu';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { N0, N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { ContextualAnalyticsData, FireScreenAnalytics } from '@atlassian/analytics-bridge';

import noResultsImg from '../../images/no-results.png';

import { Indices } from './client';
import { HeadingItem } from './items';
import ResultItem from './result-item';

/**
 * The connectStateResults function, from `react-instantsearch-dom`, takes a callback that gets passed a `searching` boolean.
 * However, this boolean is set to `false` early, while the `allSearchResults` object is only partially populated with results.
 * This is by design, for searches that would take a long time. For our use case, this results in janky UX. So, isSearching()
 * is a slightly hacky alternative to determine when the search is actually complete.
 *
 * @param {string} query The initial search query.
 * @param {AllSearchResultsType} allSearchResults An object containing all search results, grouped by section.
 * @returns {boolean} Whether there exists at least one result section where the query does not match the original search query.
 */
const isSearching = (query?: string, allSearchResults?: AllSearchResults<BasicDoc>): boolean => {
	if (!allSearchResults) {
		return false;
	}

	return Object.values(allSearchResults).some((resultSection) => resultSection.query !== query);
};

const loadingStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	height: '100%',
});

const resultsContainerStyles = xcss({
	marginInlineStart: 'space.500',
	borderInlineStartWidth: `1px`,
	borderInlineStartStyle: `solid`,
	borderImage: `linear-gradient(to bottom, ${token(
		'color.border',
		N300,
	)}, ${token('elevation.surface.overlay', N0)}) 1 100%`,
});

type ResultsProps = {
	onResultClick?(): void;
} & StateResultsProvided<BasicDoc>;

/**
 * __Results__
 */

const Results = connectStateResults<ResultsProps>(
	({ onResultClick, searchState: search, allSearchResults }) => {
		if (!search.query || search.query === '') {
			return null;
		}

		if (isSearching(search.query, allSearchResults)) {
			return (
				<Box xcss={loadingStyles} paddingBlock="space.200">
					<Spinner />
				</Box>
			);
		}

		const results = allSearchResults
			? Object.entries(allSearchResults).filter(([, searchResults]) => {
					return searchResults.hits.length;
				})
			: [];

		const renderedResults = results.length ? (
			results.map(([indexName, searchResults]) => (
				<Section key={indexName}>
					<HeadingItem indexName={indexName as Indices}>
						<Heading size="small" as="h4">
							{indexName}
						</Heading>
					</HeadingItem>
					<Box xcss={resultsContainerStyles}>
						{searchResults.hits.map((hit) => (
							<ResultItem
								indexName={indexName as Indices}
								key={`${indexName}--${hit.id}`}
								hit={hit}
								onClick={onResultClick}
							/>
						))}
					</Box>
				</Section>
			))
		) : (
			<EmptyState
				imageUrl={noResultsImg}
				header={`We can't find anything for "${search.query}".`}
				description="Try a different term or browse our components."
				primaryAction={<LinkButton href="/components">Browse components</LinkButton>}
			/>
		);

		return (
			<ContextualAnalyticsData
				attributes={{
					resultsCount: results ? results.length : 0,
				}}
			>
				<FireScreenAnalytics key={search.query} />
				{renderedResults}
			</ContextualAnalyticsData>
		);
	},
);

export default Results;
