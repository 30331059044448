import Link from '@atlaskit/link';
import ModalDefault from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-default";
import ModalWarning from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-warning";
import ModalDanger from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-danger";
import ModalWidth from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-width";
import ModalScroll from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-scroll";
import FocusSpecificElement from "../../../../../../packages/design-system/modal-dialog/examples/constellation/focus-specific-element";
import ModalForm from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-form";
import ModalSelect from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-select";
import ModalHeader from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-header-default";
import ModalCustomHeader from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-header-custom";
import ModalFooter from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-footer-default";
import ModalCustomFooter from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-footer-custom";
import ModalCurrentSurface from "../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-current-surface";
import RAW_UNSAFE_atlaskitSLASHlink from "!!raw-loader!@atlaskit/link";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_default from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_warning from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-warning";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_danger from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-danger";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_width from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-width";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_scroll from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-scroll";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHfocus_specific_element from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/focus-specific-element";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_form from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-form";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_select from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-select";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_header_default from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-header-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_header_custom from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-header-custom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_footer_default from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-footer-default";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_footer_custom from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-footer-custom";
import RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_current_surface from "!!raw-loader!../../../../../../packages/design-system/modal-dialog/examples/constellation/modal-current-surface";
import * as React from 'react';
export default {
  Link,
  ModalDefault,
  ModalWarning,
  ModalDanger,
  ModalWidth,
  ModalScroll,
  FocusSpecificElement,
  ModalForm,
  ModalSelect,
  ModalHeader,
  ModalCustomHeader,
  ModalFooter,
  ModalCustomFooter,
  ModalCurrentSurface,
  RAW_UNSAFE_atlaskitSLASHlink,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_warning,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_danger,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_width,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_scroll,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHfocus_specific_element,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_form,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_select,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_header_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_header_custom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_footer_default,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_footer_custom,
  RAW_UNSAFEUPUPSLASHUPUPSLASHexamplesSLASHconstellationSLASHmodal_current_surface,
  React
};