import perfectPairLight from "../../../../src/pages/whats-new/typography-and-iconography-updates/_images/perfect-pair.light.png";
import perfectPairDark from "../../../../src/pages/whats-new/typography-and-iconography-updates/_images/perfect-pair.dark.png";
import iconsLight from "../../../../src/pages/whats-new/typography-and-iconography-updates/_images/icons.light.png";
import iconsDark from "../../../../src/pages/whats-new/typography-and-iconography-updates/_images/icons.dark.png";
import * as React from 'react';
export default {
  perfectPairLight,
  perfectPairDark,
  iconsLight,
  iconsDark,
  React
};