import React from 'react';

import { CodeBlock } from '@atlaskit/code';
import { Box } from '@atlaskit/primitives';
import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

const sansSerifFont = `font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", sans-serif`;
const monospaceFont = `font-family: ui-monospace, Menlo, "Segoe UI Mono", "Ubuntu Mono", monospace`;

/**
 * __Typography product font stacks__
 */
export const TypographyProductFontStacks = () => {
	return (
		<Table>
			<THead>
				<TR isBodyRow={false}>
					<TH width="50%">Sans serif</TH>
					<TH width="50%">Monospaced</TH>
				</TR>
			</THead>
			<TBody>
				<TR>
					<TD>
						<Box>
							<CodeBlock showLineNumbers={false} text={sansSerifFont} shouldWrapLongLines />
						</Box>
					</TD>
					<TD>
						<Box>
							<CodeBlock showLineNumbers={false} text={monospaceFont} shouldWrapLongLines />
						</Box>
					</TD>
				</TR>
			</TBody>
		</Table>
	);
};
