/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { Index } from 'react-instantsearch-dom';

import { MenuGroup } from '@atlaskit/menu';
import { ContextualAnalyticsData, INLINE_DIALOG } from '@atlassian/analytics-bridge';

import Results from './results';

type SearchClientProps = {
	onResultClick?(): void;
	testId?: string;
};

export type Indices = 'Components' | 'Foundations' | 'Content' | 'Get started' | 'Tokens';

const SearchClient = ({ onResultClick, testId }: SearchClientProps) => {
	const indices: { name: Indices; title: Indices }[] = [
		{ name: 'Components', title: 'Components' },
		{ name: 'Foundations', title: 'Foundations' },
		{ name: 'Content', title: 'Content' },
		{ name: 'Get started', title: 'Get started' },
		{ name: 'Tokens', title: 'Tokens' },
	];

	return (
		<React.Fragment>
			{indices.map(({ name }) => (
				<Index key={name} indexName={name} />
			))}

			<ContextualAnalyticsData sourceType={INLINE_DIALOG} sourceName="search">
				<MenuGroup testId={testId ? `${testId}-results` : undefined}>
					<Results onResultClick={onResultClick} />
				</MenuGroup>
			</ContextualAnalyticsData>
		</React.Fragment>
	);
};

export default SearchClient;
