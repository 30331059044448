import buttonAndIcon from "../../../../src/pages/whats-new/evolving-button-and-links/_images/button-and-icon.png";
import buttonSpacingBeforeLight from "../../../../src/pages/whats-new/evolving-button-and-links/_images/button-spacing-before.light.png";
import buttonSpacingAfterLight from "../../../../src/pages/whats-new/evolving-button-and-links/_images/button-spacing-after.light.png";
import buttonSpacingBeforeDark from "../../../../src/pages/whats-new/evolving-button-and-links/_images/button-spacing-before.dark.png";
import buttonSpacingAfterDark from "../../../../src/pages/whats-new/evolving-button-and-links/_images/button-spacing-after.dark.png";
import * as React from 'react';
export default {
  buttonAndIcon,
  buttonSpacingBeforeLight,
  buttonSpacingAfterLight,
  buttonSpacingBeforeDark,
  buttonSpacingAfterDark,
  React
};