import React from 'react';

import { token } from '@atlaskit/tokens';

const AccentIcon = () => (
	<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_3012_141801)">
			<rect width="16" height="16" rx="8" fill={token('color.icon.inverse')} />
			<rect
				x="-6.1479"
				y="10.8521"
				width="24"
				height="4.05229"
				transform="rotate(-45 -6.1479 10.8521)"
				fill={token('color.background.accent.yellow.subtle')}
			/>
			<rect
				x="-3.28252"
				y="13.7174"
				width="24"
				height="4.05229"
				transform="rotate(-45 -3.28252 13.7174)"
				fill={token('color.background.accent.magenta.subtle')}
			/>
			<rect
				x="-0.417175"
				y="16.5828"
				width="24"
				height="4.05229"
				transform="rotate(-45 -0.417175 16.5828)"
				fill={token('color.background.accent.purple.subtle')}
			/>
			<rect
				x="2.4483"
				y="19.4482"
				width="24"
				height="4.05229"
				transform="rotate(-45 2.4483 19.4482)"
				fill={token('color.background.accent.teal.subtle')}
			/>
		</g>
		<defs>
			<clipPath id="clip0_3012_141801">
				<rect width="16" height="16" rx="8" fill={token('color.icon.inverse')} />
			</clipPath>
		</defs>
	</svg>
);

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default AccentIcon;
