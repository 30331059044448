import React from 'react';

import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';

import { TypographyTable } from '../index';

const bodyValuesLegacy = [
	{
		previewText: '-',
		tokenName: '-',
	},
	{
		previewText: 'Aa',
		tokenName: 'font.body',
		fontSize: '14px',
		lineHeight: '20px',
		fontWeight: 400,
		paragraphSpacing: '12px',
	},
	{
		previewText: 'Aa',
		tokenName: 'font.body.small',
		fontSize: '11px',
		lineHeight: '16px',
		fontWeight: 400,
		paragraphSpacing: '8px',
	},
];

const bodyValuesModernized = [
	{
		previewText: 'Aa',
		tokenName: 'font.body.large',
		fontSize: '16px',
		lineHeight: '24px',
		fontWeight: 400,
		paragraphSpacing: '16px',
	},
	{
		previewText: 'Aa',
		tokenName: 'font.body',
		fontSize: '14px',
		lineHeight: '20px',
		fontWeight: 400,
		paragraphSpacing: '12px',
	},
	{
		previewText: 'Aa',
		tokenName: 'font.body.small',
		fontSize: '12px',
		lineHeight: '16px',
		fontWeight: 400,
		paragraphSpacing: '8px',
	},
];

/**
 * __Typography body table__
 */
export const TypographyBodyTable = () => {
	return (
		<Tabs id="typography-body-table-container">
			<TabList>
				<Tab>Refreshed/Modernized</Tab>
				<Tab>Legacy</Tab>
			</TabList>
			<TabPanel>
				<TypographyTable
					values={bodyValuesModernized}
					hasParagraphSpacing
					typographyTheme="typography-modernized"
					testId="typography-body-table-modernized"
				/>
			</TabPanel>
			<TabPanel>
				<TypographyTable
					values={bodyValuesLegacy}
					hasParagraphSpacing
					typographyTheme="typography-adg3"
					testId="typography-body-table-legacy"
				/>
			</TabPanel>
		</Tabs>
	);
};
