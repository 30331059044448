import React, { useEffect, useState } from 'react';

import { graphql, StaticQuery } from 'gatsby';

import { Status } from '@af/design-system-docs-ui';
import { useSetColorMode } from '@atlaskit/app-provider';
import Button from '@atlaskit/button/new';
import DropdownMenu, { DropdownItemRadio, DropdownItemRadioGroup } from '@atlaskit/dropdown-menu';
import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { LOCAL_STORAGE_THEME_KEY } from '../../utils/constants';

const appThemeData = {
	light: {
		label: 'Light',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
	dark: {
		label: 'Dark',
		description: null,
		imageSrc: 'modeDarkThumb',
		status: null,
	},
	auto: {
		label: 'Match browser',
		description: null,
		imageSrc: 'modeLightThumb',
		status: null,
	},
} as const;

type AppTheme = keyof typeof appThemeData;

const ThemeImg = ({ src }: { src: string }) => (
	<Box
		alt="alt"
		as="img"
		src={src}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
		xcss={xcss({
			width: '4rem',
			height: '3rem',
			borderRadius: 'border.radius',
		})}
	/>
);

const isValidAppTheme = (theme: string): theme is AppTheme =>
	Object.keys(appThemeData).includes(theme);

export default function Settings() {
	const [isOpen, setIsOpen] = useState(false);
	const [appTheme, setAppTheme] = useState<AppTheme>('light');
	const [systemTheme, setSystemTheme] = useState<'light' | 'dark'>('light');

	const setColorMode = useSetColorMode();

	// Color theme
	useEffect(() => {
		const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_THEME_KEY);
		if (localStorageTheme && isValidAppTheme(localStorageTheme)) {
			// If a light/dark/auto theme has previously been set, use that theme
			setAppTheme(localStorageTheme);
			setColorMode(localStorageTheme);
		} else {
			// If nothing set previously, set default theme to 'auto' and load tokens
			setAppTheme('auto');
			setColorMode('auto');
		}
	}, [setColorMode]);

	useEffect(() => {
		const mql = window.matchMedia('(prefers-color-scheme: dark)');
		const listener = (e: MediaQueryListEvent) => setSystemTheme(e.matches ? 'dark' : 'light');

		mql.addEventListener('change', listener);
		return () => mql.removeEventListener('change', listener);
	}, []);

	const handleAppThemeChange = (theme: AppTheme) => {
		// Color theme
		if (isValidAppTheme(theme)) {
			setAppTheme(theme);
			localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);
			setColorMode(theme || appTheme);
		}
	};

	return (
		<StaticQuery
			query={graphql`
				query modeLightThumb {
					modeLightThumb: file(relativePath: { eq: "theme-thumbs/LightThumb.svg" }) {
						publicURL
					}
					modeDarkThumb: file(relativePath: { eq: "theme-thumbs/DarkThumb.svg" }) {
						publicURL
					}
				}
			`}
			render={(data) => (
				<DropdownMenu<HTMLButtonElement>
					placement="bottom-end"
					isOpen={isOpen}
					onOpenChange={() => setIsOpen(!isOpen)}
					trigger={({ triggerRef, onClick, ...triggerProps }) => (
						<Button
							ref={triggerRef}
							onClick={(e) => {
								// @ts-expect-error
								onClick(e);
								setIsOpen(!isOpen);
							}}
							{...triggerProps}
						>
							Theme
						</Button>
					)}
				>
					<DropdownItemRadioGroup id="color-theme" title="Color theme">
						{Object.entries(appThemeData).map(([theme, metadata]) => (
							<DropdownItemRadio
								id={theme}
								key={theme}
								isSelected={appTheme === theme}
								onClick={() => handleAppThemeChange(theme as AppTheme)}
							>
								<Inline space="space.150" alignBlock="center">
									<ThemeImg
										src={
											data[
												appThemeData[theme === 'auto' ? systemTheme : (theme as AppTheme)].imageSrc
											].publicURL
										}
									/>
									<Stack>
										<Text weight="medium">
											{metadata.label} {metadata.status && <Status type={metadata.status} />}
										</Text>
										<Text size="UNSAFE_small" color="color.text.subtle">
											{metadata.description}
										</Text>
									</Stack>
								</Inline>
							</DropdownItemRadio>
						))}
					</DropdownItemRadioGroup>
					<VisuallyHidden>
						<Button onClick={() => setIsOpen(false)}>Close theme selector</Button>
					</VisuallyHidden>
				</DropdownMenu>
			)}
		/>
	);
}
