/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import type { ReactNode } from 'react';

import { jsx } from '@compiled/react';

import { AtlassianIcon } from '@atlaskit/logo';
import { Inline } from '@atlaskit/primitives';
import {
	CommonActions,
	HomeActions,
	NavLogo,
	Search,
	SideNavToggleButton,
	TopBar,
	UserActions,
} from '@atlassian/navigation-system';

import { ADSLogo } from './ads-logo';
import type { NavigationItem } from './types';

export const TopNavigation = ({
	id,
	navigation,
	onSearchOpen,
	ThemeSwitcher,
}: {
	id?: string;
	navigation: NavigationItem[];
	onSearchOpen: () => void;
	ThemeSwitcher: ReactNode;
}) => {
	return (
		<TopBar id={id}>
			<HomeActions>
				<SideNavToggleButton
					expandLabel="Open menu"
					collapseLabel="Close menu"
					defaultCollapsed={false}
				/>
				<NavLogo
					label="Atlassian Design System home"
					href="/"
					logo={ADSLogo}
					icon={() => (
						<AtlassianIcon label="Atlassian Design System" appearance="brand" size="small" />
					)}
				/>
			</HomeActions>
			<CommonActions>
				<Search label="Search the Atlassian Design System" onClick={onSearchOpen} />
			</CommonActions>
			<UserActions>
				<Inline as="li">{ThemeSwitcher}</Inline>
			</UserActions>
		</TopBar>
	);
};
