exports.components = {
  "component---src-pages-404-images-not-found-tsx": () => import("./../../../src/pages/404/_images/not-found.tsx" /* webpackChunkName: "component---src-pages-404-images-not-found-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-components-index-tsx": () => import("./../../../src/pages/components/index.tsx" /* webpackChunkName: "component---src-pages-components-index-tsx" */),
  "component---src-pages-contact-us-content-mdx": () => import("./../../../src/pages/contact-us/content.mdx" /* webpackChunkName: "component---src-pages-contact-us-content-mdx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-contribution-mdx": () => import("./../../../src/pages/contribution.mdx" /* webpackChunkName: "component---src-pages-contribution-mdx" */),
  "component---src-pages-foundations-content-index-tsx": () => import("./../../../src/pages/foundations/content/index.tsx" /* webpackChunkName: "component---src-pages-foundations-content-index-tsx" */),
  "component---src-pages-foundations-index-tsx": () => import("./../../../src/pages/foundations/index.tsx" /* webpackChunkName: "component---src-pages-foundations-index-tsx" */),
  "component---src-pages-foundations-tokens-index-tsx": () => import("./../../../src/pages/foundations/tokens/index.tsx" /* webpackChunkName: "component---src-pages-foundations-tokens-index-tsx" */),
  "component---src-pages-get-started-index-tsx": () => import("./../../../src/pages/get-started/index.tsx" /* webpackChunkName: "component---src-pages-get-started-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-license-content-mdx": () => import("./../../../src/pages/license/content.mdx" /* webpackChunkName: "component---src-pages-license-content-mdx" */),
  "component---src-pages-license-index-tsx": () => import("./../../../src/pages/license/index.tsx" /* webpackChunkName: "component---src-pages-license-index-tsx" */),
  "component---src-pages-llms-content-mdx": () => import("./../../../src/pages/llms/content.mdx" /* webpackChunkName: "component---src-pages-llms-content-mdx" */),
  "component---src-pages-llms-index-tsx": () => import("./../../../src/pages/llms/index.tsx" /* webpackChunkName: "component---src-pages-llms-index-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */),
  "component---src-pages-whats-new-evolving-button-and-links-index-tsx": () => import("./../../../src/pages/whats-new/evolving-button-and-links/index.tsx" /* webpackChunkName: "component---src-pages-whats-new-evolving-button-and-links-index-tsx" */),
  "component---src-pages-whats-new-index-tsx": () => import("./../../../src/pages/whats-new/index.tsx" /* webpackChunkName: "component---src-pages-whats-new-index-tsx" */),
  "component---src-pages-whats-new-typography-and-iconography-updates-index-tsx": () => import("./../../../src/pages/whats-new/typography-and-iconography-updates/index.tsx" /* webpackChunkName: "component---src-pages-whats-new-typography-and-iconography-updates-index-tsx" */),
  "component---src-templates-guideline-page-tsx": () => import("./../../../src/templates/guideline-page.tsx" /* webpackChunkName: "component---src-templates-guideline-page-tsx" */),
  "component---src-templates-package-page-index-tsx": () => import("./../../../src/templates/package-page/index.tsx" /* webpackChunkName: "component---src-templates-package-page-index-tsx" */),
  "component---src-templates-package-page-sub-page-tsx": () => import("./../../../src/templates/package-page/sub-page.tsx" /* webpackChunkName: "component---src-templates-package-page-sub-page-tsx" */)
}

