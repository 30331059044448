import React, {
	type SyntheticEvent,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';

import SearchIcon from '@atlaskit/icon/core/search';
import Modal, { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { Show, UNSAFE_useMediaQuery } from '@atlaskit/primitives/responsive';
import Textfield from '@atlaskit/textfield';

import {
	getCoreIcons,
	getIconLabCoreIcons,
	getUtilityIcons,
	globalCoreIconImportPrefix,
	globalUtilityIconImportPrefix,
	iconLabCoreIconImportPrefix,
} from '../helpers';
import { type NewIconData } from '../types';

import GridSection from './grid-section';
import { IconDetails } from './icon-details';

const fuseOptions: Fuse.IFuseOptions<NewIconData> = {
	keys: [
		{
			name: 'componentName',
			weight: 1,
		},
		{
			name: 'packageName',
			weight: 1,
		},
		{
			name: 'iconName',
			weight: 1,
		},
		{
			name: 'keywords',
			weight: 1,
		},
	],
	useExtendedSearch: true,
	threshold: 0.4,
	ignoreLocation: true,
};

const IconDetailsBoxStyles = xcss({
	position: 'sticky',
	insetBlockStart: '64px',
});

const modalIconDetailsStyles = xcss({
	width: '100%',
	borderRadius: '4px',
	overflow: 'auto',
});

type IconExplorerProps = {
	testId?: string;
};

const SearchIconBefore = () => (
	<Box paddingInlineStart="space.100">
		<SearchIcon label="Search" color="currentColor" />
	</Box>
);

export function IconExplorer({ testId }: IconExplorerProps) {
	const [selectedIcon, setSelectedIcon] = useState<NewIconData>();
	const coreFuseIndex = useRef<Fuse<NewIconData> | null>(null);
	const utilityFuseIndex = useRef<Fuse<NewIconData> | null>(null);
	const iconLabFuseIndex = useRef<Fuse<NewIconData> | null>(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [searchedIcons, setSearchedIcons] = useState<{
		core: NewIconData[] | undefined;
		utility: NewIconData[] | undefined;
		iconLab: NewIconData[] | undefined;
	}>();
	const [allIcons, setAllIcons] = useState<{
		core: NewIconData[] | undefined;
		utility: NewIconData[] | undefined;
		iconLab: NewIconData[] | undefined;
	}>();

	const search = (query: string) =>
		setSearchedIcons({
			core: coreFuseIndex.current
				? coreFuseIndex.current.search<NewIconData>(query).map((result) => result.item)
				: undefined,
			utility: utilityFuseIndex.current
				? utilityFuseIndex.current.search<NewIconData>(query).map((result) => result.item)
				: undefined,
			iconLab: iconLabFuseIndex.current
				? iconLabFuseIndex.current.search<NewIconData>(query).map((result) => result.item)
				: undefined,
		});
	const debouncedSearch = useMemo(() => debounce(search, 300), []);

	const handleSearch = useCallback(
		(query: string) => {
			if (query === '') {
				setSearchedIcons(allIcons);
				return;
			}

			// Clear searched tokens to enter loading state
			setSearchedIcons(undefined);

			if (query !== '') {
				debouncedSearch(query);
			}
		},
		[debouncedSearch, allIcons],
	);

	const isBelowSm = UNSAFE_useMediaQuery('below.sm');

	useEffect(() => {
		const coreIconsPromise = getCoreIcons();
		const utilityIconsPromise = getUtilityIcons();
		const iconLabCoreIconsPromise = getIconLabCoreIcons();

		Promise.all([coreIconsPromise, utilityIconsPromise, iconLabCoreIconsPromise]).then(
			([coreIcons, utilityIcons, iconLabIcons]) => {
				if (coreIcons) {
					coreFuseIndex.current = new Fuse(Object.values(coreIcons), fuseOptions);
				}
				if (utilityIcons) {
					utilityFuseIndex.current = new Fuse(Object.values(utilityIcons), fuseOptions);
				}
				if (iconLabIcons) {
					iconLabFuseIndex.current = new Fuse(Object.values(iconLabIcons), fuseOptions);
				}
				const allIcons = {
					core: coreIcons ? Object.values(coreIcons) : undefined,
					utility: utilityIcons ? Object.values(utilityIcons) : undefined,
					iconLab: iconLabIcons ? Object.values(iconLabIcons) : undefined,
				};
				setAllIcons(allIcons);
				setSearchedIcons(allIcons);
			},
		);
	}, []);

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedIcon(undefined);
	};

	const updateSelectedIcon = useCallback(
		(icon: NewIconData) => {
			setSelectedIcon((oldIcon) => (oldIcon?.packageName !== icon.packageName ? icon : undefined));
			isBelowSm?.matches && setIsModalOpen(true);
		},
		[setSelectedIcon, setIsModalOpen, isBelowSm?.matches],
	);

	return (
		<Inline space="space.200" testId={testId}>
			<Stack space="space.400" grow="fill">
				<Textfield
					placeholder="Search"
					elemBeforeInput={<SearchIconBefore />}
					onChange={(evt: SyntheticEvent<HTMLInputElement>) =>
						handleSearch(evt.currentTarget.value)
					}
				/>

				{searchedIcons?.core && searchedIcons.core.length > 0 && (
					<GridSection
						isLegacy={false}
						importPath={globalCoreIconImportPrefix}
						heading="Core"
						icons={searchedIcons?.core}
						onIconClick={updateSelectedIcon}
						selectedIcon={!isModalOpen ? selectedIcon?.packageName : undefined}
					/>
				)}

				{searchedIcons?.utility && searchedIcons.utility.length > 0 && (
					<GridSection
						isLegacy={false}
						importPath={globalUtilityIconImportPrefix}
						heading="Utility"
						icons={searchedIcons?.utility}
						onIconClick={updateSelectedIcon}
						selectedIcon={!isModalOpen ? selectedIcon?.packageName : undefined}
					/>
				)}

				{searchedIcons?.iconLab && searchedIcons.iconLab.length > 0 && (
					<GridSection
						isLegacy={false}
						importPath={iconLabCoreIconImportPrefix}
						heading="Icon Lab"
						icons={searchedIcons?.iconLab}
						onIconClick={updateSelectedIcon}
						selectedIcon={!isModalOpen ? selectedIcon?.packageName : undefined}
					/>
				)}
			</Stack>
			<ModalTransition>
				{isModalOpen && selectedIcon && (
					// Close button is contained within the IconDetails
					// eslint-disable-next-line @atlaskit/design-system/use-modal-dialog-close-button
					<Modal onClose={closeModal}>
						<Box xcss={modalIconDetailsStyles}>
							<IconDetails icon={selectedIcon} isModal onClose={closeModal} />
						</Box>
					</Modal>
				)}
			</ModalTransition>
			<Show above="sm">
				<Box xcss={IconDetailsBoxStyles}>
					<IconDetails icon={selectedIcon} />
				</Box>
			</Show>
		</Inline>
	);
}
