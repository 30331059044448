/**
 * Set of platform feature flags that should be enabled by default on the entire atlassian.design website.
 */
export const featureFlagsPlatform = {
	'platform-component-visual-refresh': true,
};

// featureFlags are feature flags the the user can enable through localstorage
// These are on the client-side
const featureFlags = {};

export type FeatureFlag = keyof typeof featureFlags;

export default featureFlags;
