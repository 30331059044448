/**
 * THIS FILE WAS CREATED VIA CODEGEN DO NOT MODIFY {@see http://go/af-codegen}
 *
 * To change the format of this file, modify `createIconDocsNew` in icon-build-process/src/create-icon-docs.tsx.
 *
 * @codegen <<SignedSource::2ff15dccd79cac17aeed3438db1b76ac>>
 * @codegenCommand yarn build:icon-glyphs
 */
interface metadata {
	/**
	 * Default component name for the icon
	 */
	componentName: string;

	/**
	 * Package path to the icon component
	 */
	package: string;

	/**
	 * The category of the icon:
	 * - 'single-purpose' icons should only be used for the purposes listed in the 'usage' field
	 * - 'multi-purpose' icons are designed for various use cases and don't have the same usage restrictions
	 * - 'utility' icons are used for utility purposes
	 */
	categorization: 'single-purpose' | 'multi-purpose' | 'utility';

	/**
	 * The type of the icon - either a 16px 'core' icon, or a 12px 'utility' icon
	 */
	type: 'core' | 'utility';

	/**
	 * Usage guidelines for the icon. For single-purpose icons,
	 */
	usage?: string;

	/**
	 * Additional keywords used to assist in search/lookup of an icon
	 */
	keywords: string[];

	/**
	 * The name of the team owning the icon
	 */
	team: string;

	/**
	 * The status of the icon
	 */
	status?: 'draft' | 'ready-to-publish' | 'published' | 'modified' | 'deprecated';

	/**
	 * Contact slack channel for the team owning the icon
	 */
	slackChannel?: string;

	/**
	 * A list of keys for old icons that have been replaced by this icon
	 */
	oldName?: string[];

	/**
	 * A replacement icon if this icon has been deprecated
	 */
	replacement?: {
		name: string;
		type: 'core' | 'utility';
		location: '@atlaskit/icon' | '@atlaskit/icon-lab' | '@atlassian/icon-private';
	};
}

const metadata: Record<string, metadata> = {
	'ai-generative-chapters': {
		keywords: [
			'ai-generative-chapters',
			'aigenerativechapters',
			'icon',
			'icon-lab',
			'core',
			'book',
			'AI',
			'automation',
		],
		componentName: 'AiGenerativeChaptersIcon',
		package: '@atlaskit/icon-lab/core/ai-generative-chapters',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for auto chapters functionality in Loom.',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'ai-generative-remove': {
		keywords: [
			'ai-generative-remove',
			'aigenerativeremove',
			'icon',
			'icon-lab',
			'core',
			'eraser',
			'AI',
			'automation',
		],
		componentName: 'AiGenerativeRemoveIcon',
		package: '@atlaskit/icon-lab/core/ai-generative-remove',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for remove filler words functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'ai-generative-remove-silence': {
		keywords: [
			'ai-generative-remove-silence',
			'aigenerativeremovesilence',
			'icon',
			'icon-lab',
			'core',
			'empty',
			'AI',
			'automation',
			'pauses',
			'silences',
		],
		componentName: 'AiGenerativeRemoveSilenceIcon',
		package: '@atlaskit/icon-lab/core/ai-generative-remove-silence',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for silence removal functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'ai-generative-text': {
		keywords: [
			'ai-generative-text',
			'aigenerativetext',
			'icon',
			'icon-lab',
			'core',
			'pencil',
			'AI',
			'automation',
		],
		componentName: 'AiGenerativeTextIcon',
		package: '@atlaskit/icon-lab/core/ai-generative-text',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for auto draft functionality in Loom.',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'ai-generative-text-title': {
		keywords: [
			'ai-generative-text-title',
			'aigenerativetexttitle',
			'icon',
			'icon-lab',
			'core',
			'text',
			'AI',
			'automation',
			'header',
		],
		componentName: 'AiGenerativeTextTitleIcon',
		package: '@atlaskit/icon-lab/core/ai-generative-text-title',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for auto titles functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'align-content-bottom': {
		keywords: [
			'align-content-bottom',
			'aligncontentbottom',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'bottom',
			'vertical',
		],
		componentName: 'AlignContentBottomIcon',
		package: '@atlaskit/icon-lab/core/align-content-bottom',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning bottom edges of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-content-center-horizontal': {
		keywords: [
			'align-content-center-horizontal',
			'aligncontentcenterhorizontal',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'center',
			'middle',
			'horizontal',
		],
		componentName: 'AlignContentCenterHorizontalIcon',
		package: '@atlaskit/icon-lab/core/align-content-center-horizontal',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning horizontal centers of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-content-center-vertical': {
		keywords: [
			'align-content-center-vertical',
			'aligncontentcentervertical',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'center',
			'vertical',
		],
		componentName: 'AlignContentCenterVerticalIcon',
		package: '@atlaskit/icon-lab/core/align-content-center-vertical',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning vertical centers of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-content-left': {
		keywords: [
			'align-content-left',
			'aligncontentleft',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'left',
			'horizontal',
		],
		componentName: 'AlignContentLeftIcon',
		package: '@atlaskit/icon-lab/core/align-content-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning left edges of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-content-right': {
		keywords: [
			'align-content-right',
			'aligncontentright',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'right',
			'horizontal',
		],
		componentName: 'AlignContentRightIcon',
		package: '@atlaskit/icon-lab/core/align-content-right',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning right edges of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-content-top': {
		keywords: [
			'align-content-top',
			'aligncontenttop',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'content',
			'top',
			'vertical',
		],
		componentName: 'AlignContentTopIcon',
		package: '@atlaskit/icon-lab/core/align-content-top',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for aligning top edges of content in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-position-bottom': {
		keywords: [
			'align-position-bottom',
			'alignpositionbottom',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'middle',
			'text position',
			'bottom',
			'vertical',
		],
		componentName: 'AlignPositionBottomIcon',
		package: '@atlaskit/icon-lab/core/align-position-bottom',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Bottom-aligned text position within sticky notes in Whiteboards.',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-position-center-vertical': {
		keywords: [
			'align-position-center-vertical',
			'alignpositioncentervertical',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'middle',
			'center',
			'text position',
			'vertical',
		],
		componentName: 'AlignPositionCenterVerticalIcon',
		package: '@atlaskit/icon-lab/core/align-position-center-vertical',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Center-aligned text position within sticky notes in Whiteboards.',
		team: 'Whiteboards',
		status: 'published',
	},
	'align-position-top': {
		keywords: [
			'align-position-top',
			'alignpositiontop',
			'icon',
			'icon-lab',
			'core',
			'align',
			'alignment',
			'top edge',
			'text position',
			'vertical',
		],
		componentName: 'AlignPositionTopIcon',
		package: '@atlaskit/icon-lab/core/align-position-top',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Top-aligned text position within sticky notes in Whiteboards.',
		team: 'Whiteboards',
		status: 'published',
	},
	'arrows-diagonal-up-right-down-left': {
		keywords: [
			'arrows-diagonal-up-right-down-left',
			'arrowsdiagonaluprightdownleft',
			'icon',
			'icon-lab',
			'core',
			'Arrows',
			'Switch',
			'Space',
			'Swap',
			'crossflow',
		],
		componentName: 'ArrowsDiagonalUpRightDownLeftIcon',
		package: '@atlaskit/icon-lab/core/arrows-diagonal-up-right-down-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used within Jira Pages to connect or switch to a Space or page.',
		team: 'Confluence Integrations',
		status: 'published',
	},
	'assets-data-manager': {
		keywords: [
			'assets-data-manager',
			'assetsdatamanager',
			'icon',
			'icon-lab',
			'core',
			'data manager',
			'assets',
			'CMDB',
			'configuration management database',
		],
		componentName: 'AssetsDataManagerIcon',
		package: '@atlaskit/icon-lab/core/assets-data-manager',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for JSM Assets.',
		team: 'Assets',
		status: 'published',
	},
	'assets-graph': {
		keywords: ['assets-graph', 'assetsgraph', 'icon', 'icon-lab', 'core', 'assets', 'graph'],
		componentName: 'AssetsGraphIcon',
		package: '@atlaskit/icon-lab/core/assets-graph',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing Assets graph',
		team: 'Assets',
		status: 'published',
	},
	'assets-schema': {
		keywords: [
			'assets-schema',
			'assetsschema',
			'icon',
			'icon-lab',
			'core',
			'assets',
			'CMDB',
			'configuration management database',
		],
		componentName: 'AssetsSchemaIcon',
		package: '@atlaskit/icon-lab/core/assets-schema',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for JSM Assets.',
		team: 'Assets',
		status: 'published',
	},
	'audio-waveform': {
		keywords: [
			'audio-waveform',
			'audiowaveform',
			'icon',
			'icon-lab',
			'core',
			'waveform',
			'audio',
			'soundwave',
			'dialogue',
			'speak',
		],
		componentName: 'AudioWaveformIcon',
		package: '@atlaskit/icon-lab/core/audio-waveform',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: show waveform editor in Loom.',
		team: 'Loom',
		status: 'published',
	},
	'beach-umbrella': {
		keywords: [
			'beach-umbrella',
			'beachumbrella',
			'icon',
			'icon-lab',
			'core',
			'vacation',
			'beach umbrella',
			'sun shade',
			'holiday',
		],
		componentName: 'BeachUmbrellaIcon',
		package: '@atlaskit/icon-lab/core/beach-umbrella',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'User-selectable icon choice for calendars',
		team: 'Growth Buy',
		status: 'published',
	},
	blur: {
		keywords: ['blur', 'icon', 'icon-lab', 'core'],
		componentName: 'BlurIcon',
		package: '@atlaskit/icon-lab/core/blur',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for blurring an element in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'book-open': {
		keywords: [
			'book-open',
			'bookopen',
			'icon',
			'icon-lab',
			'core',
			'book',
			'knowledgebase article',
		],
		componentName: 'BookOpenIcon',
		package: '@atlaskit/icon-lab/core/book-open',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: None',
		team: 'Design System Team',
		status: 'published',
	},
	'browser-extension': {
		keywords: [
			'browser-extension',
			'browserextension',
			'icon',
			'icon-lab',
			'core',
			'extension',
			'puzzle piece',
			'add on',
			'jigsaw',
			'chrome web store',
		],
		componentName: 'BrowserExtensionIcon',
		package: '@atlaskit/icon-lab/core/browser-extension',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for chrome extensions',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	calculate: {
		keywords: ['calculate', 'icon', 'icon-lab', 'core', 'Calculate', 'databases', 'summation'],
		componentName: 'CalculateIcon',
		package: '@atlaskit/icon-lab/core/calculate',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Calculate for databases',
		team: 'Databases',
		status: 'published',
	},
	'cloud-offline': {
		keywords: [
			'cloud-offline',
			'cloudoffline',
			'icon',
			'icon-lab',
			'core',
			'cloud',
			'offline',
			'disconnected',
			'strikethrough',
		],
		componentName: 'CloudOfflineIcon',
		package: '@atlaskit/icon-lab/core/cloud-offline',
		type: 'core',
		categorization: 'single-purpose',
		usage: "Reserved for showing when a connection can't be established to the cloud.",
		team: 'Content Types',
		status: 'published',
	},
	'cloud-saved': {
		keywords: [
			'cloud-saved',
			'cloudsaved',
			'icon',
			'icon-lab',
			'core',
			'cloud',
			'check mark',
			'saved',
			'success',
		],
		componentName: 'CloudSavedIcon',
		package: '@atlaskit/icon-lab/core/cloud-saved',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for showing saved status after successful cloud synchronisation.',
		team: 'Content Types',
		status: 'published',
	},
	coins: {
		keywords: ['coins', 'icon', 'icon-lab', 'core', 'money', 'loose change', 'currency'],
		componentName: 'CoinsIcon',
		package: '@atlaskit/icon-lab/core/coins',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	'colour-none': {
		keywords: [
			'colour-none',
			'colournone',
			'icon',
			'icon-lab',
			'core',
			'Colour',
			'Color',
			'Remove',
			'None',
			'Colour picker',
		],
		componentName: 'ColourNoneIcon',
		package: '@atlaskit/icon-lab/core/colour-none',
		type: 'core',
		categorization: 'single-purpose',
		usage: "Remove colours from Whiteboard's colour picker",
		team: 'Whiteboards',
		status: 'published',
	},
	'continue-when': {
		keywords: ['continue-when', 'continuewhen', 'icon', 'icon-lab', 'core'],
		componentName: 'ContinueWhenIcon',
		package: '@atlaskit/icon-lab/core/continue-when',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for continue when delay in automation.',
		team: 'Automation',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	'cross-octagon': {
		keywords: [
			'cross-octagon',
			'crossoctagon',
			'icon',
			'icon-lab',
			'core',
			'cross',
			'stop',
			'block',
			'octagon',
		],
		componentName: 'CrossOctagonIcon',
		package: '@atlaskit/icon-lab/core/cross-octagon',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	cursor: {
		keywords: ['cursor', 'icon', 'icon-lab', 'core', 'Whiteboards', 'Cursor'],
		componentName: 'CursorIcon',
		package: '@atlaskit/icon-lab/core/cursor',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Selecting the cursor in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'cursor-strikethrough': {
		keywords: [
			'cursor-strikethrough',
			'cursorstrikethrough',
			'icon',
			'icon-lab',
			'core',
			'Cursor',
			'Cursors',
			'Hide cursor',
			'Whiteboard cursor',
			'Cursor strikethrough',
		],
		componentName: 'CursorStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/cursor-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Hide collaborator cursors in the Whiteboard',
		team: 'Whiteboards',
		status: 'published',
	},
	'database-storage': {
		keywords: [
			'database-storage',
			'databasestorage',
			'icon',
			'icon-lab',
			'core',
			'database',
			'db',
			'storage',
			'sql',
		],
		componentName: 'DatabaseStorageIcon',
		package: '@atlaskit/icon-lab/core/database-storage',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: SQL database in Developer Console',
		team: 'Ecosystem',
		status: 'published',
		slackChannel: '#forge-sql',
	},
	'database-storage-cache': {
		keywords: [
			'database-storage-cache',
			'databasestoragecache',
			'icon',
			'icon-lab',
			'core',
			'database',
			'db',
			'storage',
			'sql',
			'cache',
		],
		componentName: 'DatabaseStorageCacheIcon',
		package: '@atlaskit/icon-lab/core/database-storage-cache',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Storage cache in Developer Console.',
		team: 'Ecosystem',
		status: 'published',
		slackChannel: '#forge-sql',
	},
	'distribute-spacing-horizontal': {
		keywords: [
			'distribute-spacing-horizontal',
			'distributespacinghorizontal',
			'icon',
			'icon-lab',
			'core',
			'Distribute',
			'Horizontal spacing',
			'Tidy up',
			'Clean up',
		],
		componentName: 'DistributeSpacingHorizontalIcon',
		package: '@atlaskit/icon-lab/core/distribute-spacing-horizontal',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Evenly distributing the horizontal space between multiple selected objects on Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'distribute-spacing-vertical': {
		keywords: [
			'distribute-spacing-vertical',
			'distributespacingvertical',
			'icon',
			'icon-lab',
			'core',
			'Distribute',
			'Vertical spacing',
			'Clean up',
			'Tidy',
		],
		componentName: 'DistributeSpacingVerticalIcon',
		package: '@atlaskit/icon-lab/core/distribute-spacing-vertical',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Evenly distributing the vertical space between multiple selected objects on Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'divider-element': {
		keywords: [
			'divider-element',
			'dividerelement',
			'icon',
			'icon-lab',
			'core',
			'divider',
			'line break',
			'horizontal rule',
		],
		componentName: 'DividerElementIcon',
		package: '@atlaskit/icon-lab/core/divider-element',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for inserting divider elements in Confluence',
		team: 'editor-design',
		status: 'published',
		slackChannel: '#editor-controls-project',
	},
	'dock-toolbar-top': {
		keywords: [
			'dock-toolbar-top',
			'docktoolbartop',
			'icon',
			'icon-lab',
			'core',
			'dock to top',
			'fix to top',
			'pin to top',
		],
		componentName: 'DockToolbarTopIcon',
		package: '@atlaskit/icon-lab/core/dock-toolbar-top',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for docking a toolbar to the top of a page',
		team: 'Editor-design',
		status: 'published',
		slackChannel: '#Editor-design',
	},
	'drop-shadow': {
		keywords: ['drop-shadow', 'dropshadow', 'icon', 'icon-lab', 'core', 'Drop shadow'],
		componentName: 'DropShadowIcon',
		package: '@atlaskit/icon-lab/core/drop-shadow',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Toggle drop shadow on and off',
		team: 'Whiteboards',
		status: 'published',
	},
	duplicate: {
		keywords: ['duplicate', 'icon', 'icon-lab', 'core', 'clone', 'duplicate', 'plus'],
		componentName: 'DuplicateIcon',
		package: '@atlaskit/icon-lab/core/duplicate',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for cloning and duplicating objects.',
		team: 'Design System Team',
		status: 'published',
		slackChannel: '#icon-contributions',
	},
	editions: {
		keywords: [
			'editions',
			'icon',
			'icon-lab',
			'core',
			'gem',
			'premium',
			'diamond',
			'precious stone',
			'editions',
		],
		componentName: 'EditionsIcon',
		package: '@atlaskit/icon-lab/core/editions',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Reserved for representing premium features and functionality. Editions may replace the current premium sparkle icon.',
		team: 'Design System Team',
		status: 'published',
	},
	'expand-element': {
		keywords: [
			'expand-element',
			'expandelement',
			'icon',
			'icon-lab',
			'core',
			'expand',
			'editor object',
		],
		componentName: 'ExpandElementIcon',
		package: '@atlaskit/icon-lab/core/expand-element',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for inserting expand elements in Confluence',
		team: 'editor-design',
		status: 'published',
		slackChannel: '#editor-controls-project',
	},
	'field-text': {
		keywords: [
			'field-text',
			'fieldtext',
			'icon',
			'icon-lab',
			'core',
			'form',
			'input',
			'label',
			'cursor',
			'text field',
		],
		componentName: 'FieldTextIcon',
		package: '@atlaskit/icon-lab/core/field-text',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	'folder-shared': {
		keywords: [
			'folder-shared',
			'foldershared',
			'icon',
			'icon-lab',
			'core',
			'Shared',
			'User',
			'Public',
		],
		componentName: 'FolderSharedIcon',
		package: '@atlaskit/icon-lab/core/folder-shared',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for shared folders',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	gift: {
		keywords: ['gift', 'icon', 'icon-lab', 'core', 'gift', 'present'],
		componentName: 'GiftIcon',
		package: '@atlaskit/icon-lab/core/gift',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'User-selectable icon choice for calendars',
		team: 'Growth Buy',
		status: 'published',
	},
	'globe-strikethrough': {
		keywords: [
			'globe-strikethrough',
			'globestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'globe',
			'strikethrough',
			'private',
			'not public',
		],
		componentName: 'GlobeStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/globe-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Limited share permissions which are not public',
		team: 'Loom',
		status: 'published',
	},
	'group-selection': {
		keywords: [
			'group-selection',
			'groupselection',
			'icon',
			'icon-lab',
			'core',
			'group',
			'selection',
			'layers',
		],
		componentName: 'GroupSelectionIcon',
		package: '@atlaskit/icon-lab/core/group-selection',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Grouping selected objects in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'group-ungroup-selection': {
		keywords: [
			'group-ungroup-selection',
			'groupungroupselection',
			'icon',
			'icon-lab',
			'core',
			'ungroup',
			'selection',
			'layers',
		],
		componentName: 'GroupUngroupSelectionIcon',
		package: '@atlaskit/icon-lab/core/group-ungroup-selection',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Ungrouping selected objects in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	hand: {
		keywords: ['hand', 'icon', 'icon-lab', 'core', 'hand', 'grab', 'move', 'open palm'],
		componentName: 'HandIcon',
		package: '@atlaskit/icon-lab/core/hand',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for move/hand tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	history: {
		keywords: [
			'history',
			'icon',
			'icon-lab',
			'core',
			'version control',
			'past',
			'arrow',
			'clock',
			'time',
			'history',
			'recent',
		],
		componentName: 'HistoryIcon',
		package: '@atlaskit/icon-lab/core/history',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for history and recent',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'if-else': {
		keywords: ['if-else', 'ifelse', 'icon', 'icon-lab', 'core', 'condition', 'statement', 'branch'],
		componentName: 'IfElseIcon',
		package: '@atlaskit/icon-lab/core/if-else',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for If Else conditions',
		team: 'Automation',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	'image-alt-text': {
		keywords: ['image-alt-text', 'imagealttext', 'icon', 'icon-lab', 'core'],
		componentName: 'ImageAltTextIcon',
		package: '@atlaskit/icon-lab/core/image-alt-text',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for creating alt text for an image',
		team: 'Editor',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'image-crop': {
		keywords: ['image-crop', 'imagecrop', 'icon', 'icon-lab', 'core', 'crop', 'crop tool', 'trim'],
		componentName: 'ImageCropIcon',
		package: '@atlaskit/icon-lab/core/image-crop',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for cropping images',
		team: 'Design System Team',
		status: 'published',
	},
	incognito: {
		keywords: ['incognito', 'icon', 'icon-lab', 'core', 'Whiteboards', 'Private', 'Incognito'],
		componentName: 'IncognitoIcon',
		package: '@atlaskit/icon-lab/core/incognito',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Toggle Whiteboards private mode on and off.',
		team: 'Whiteboards',
		status: 'published',
	},
	initiative: {
		keywords: [
			'initiative',
			'icon',
			'icon-lab',
			'core',
			'fortune teller',
			'crystal ball',
			'future',
			'issue type',
			'system object',
			'work collection',
		],
		componentName: 'InitiativeIcon',
		package: '@atlaskit/icon-lab/core/initiative',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Representing Jira Initiatives',
		team: 'Jira Enterprise',
		status: 'published',
	},
	jira: {
		keywords: ['jira', 'icon', 'icon-lab', 'core'],
		componentName: 'JiraIcon',
		package: '@atlaskit/icon-lab/core/jira',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Representing JIRA',
		team: 'Whiteboards',
		status: 'published',
	},
	'jira-add': {
		keywords: ['jira-add', 'jiraadd', 'icon', 'icon-lab', 'core', 'Jira', 'Work items', 'Create'],
		componentName: 'JiraAddIcon',
		package: '@atlaskit/icon-lab/core/jira-add',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding Jira work items',
		team: 'Whiteboards',
		status: 'published',
	},
	'jira-upload': {
		keywords: [
			'jira-upload',
			'jiraupload',
			'icon',
			'icon-lab',
			'core',
			'Jira',
			'Work items',
			'Import',
			'Arrow up',
		],
		componentName: 'JiraUploadIcon',
		package: '@atlaskit/icon-lab/core/jira-upload',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for importing Jira work items',
		team: 'Whiteboards',
		status: 'published',
	},
	'layout-five-columns': {
		keywords: [
			'layout-five-columns',
			'layoutfivecolumns',
			'icon',
			'icon-lab',
			'core',
			'layout',
			'columns',
			'5 col',
			'5 cols',
		],
		componentName: 'LayoutFiveColumnsIcon',
		package: '@atlaskit/icon-lab/core/layout-five-columns',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for 5 column layout option in Confluence Editor.',
		team: 'Confluence Editor',
		status: 'published',
	},
	'layout-four-columns': {
		keywords: [
			'layout-four-columns',
			'layoutfourcolumns',
			'icon',
			'icon-lab',
			'core',
			'layout',
			'columns',
			'4 col',
			'4 cols',
		],
		componentName: 'LayoutFourColumnsIcon',
		package: '@atlaskit/icon-lab/core/layout-four-columns',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for 4 columns layout option in Confluence Editor.',
		team: 'Confluence Editor',
		status: 'published',
	},
	'link-restricted-access': {
		keywords: [
			'link-restricted-access',
			'linkrestrictedaccess',
			'icon',
			'icon-lab',
			'core',
			'link',
			'strikethrough',
			'limited',
		],
		componentName: 'LinkRestrictedAccessIcon',
		package: '@atlaskit/icon-lab/core/link-restricted-access',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for when there is limited link permissions',
		team: 'Loom',
		status: 'published',
	},
	lozenge: {
		keywords: ['lozenge', 'icon', 'icon-lab', 'core', 'insert', 'status', 'badge'],
		componentName: 'LozengeIcon',
		package: '@atlaskit/icon-lab/core/lozenge',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for inserting status lozenges in Editor.',
		team: 'Editor',
		status: 'published',
	},
	'microphone-strikethrough': {
		keywords: [
			'microphone-strikethrough',
			'microphonestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'mic',
			'no audio',
			'off',
		],
		componentName: 'MicrophoneStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/microphone-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Reserved for no microphone input',
		team: 'Loom',
		status: 'published',
	},
	'node-parallel-bottom-left': {
		keywords: [
			'node-parallel-bottom-left',
			'nodeparallelbottomleft',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'Parallel',
			'Bottom',
			'Left',
		],
		componentName: 'NodeParallelBottomLeftIcon',
		package: '@atlaskit/icon-lab/core/node-parallel-bottom-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node parallel-bottom-left',
		team: 'Whiteboards',
		status: 'published',
	},
	'node-parallel-bottom-right': {
		keywords: [
			'node-parallel-bottom-right',
			'nodeparallelbottomright',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'parallel',
			'bottom',
			'right',
		],
		componentName: 'NodeParallelBottomRightIcon',
		package: '@atlaskit/icon-lab/core/node-parallel-bottom-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node parallel-bottom-right',
		team: 'Whiteboards',
		status: 'published',
	},
	'node-parallel-top-left': {
		keywords: [
			'node-parallel-top-left',
			'nodeparalleltopleft',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'Parallel',
			'Top',
			'Left',
		],
		componentName: 'NodeParallelTopLeftIcon',
		package: '@atlaskit/icon-lab/core/node-parallel-top-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node parallel-top-left',
		team: 'Whiteboards',
		status: 'published',
	},
	'node-parallel-top-right': {
		keywords: [
			'node-parallel-top-right',
			'nodeparalleltopright',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'parallel',
			'top',
			'right',
		],
		componentName: 'NodeParallelTopRightIcon',
		package: '@atlaskit/icon-lab/core/node-parallel-top-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node parallel-top-right',
		team: 'Whiteboards',
		status: 'published',
	},
	'node-single-left': {
		keywords: [
			'node-single-left',
			'nodesingleleft',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'Single',
			'Left',
		],
		componentName: 'NodeSingleLeftIcon',
		package: '@atlaskit/icon-lab/core/node-single-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node single-left alignment',
		team: 'Whiteboards',
		status: 'published',
	},
	'node-single-right': {
		keywords: [
			'node-single-right',
			'nodesingleright',
			'icon',
			'icon-lab',
			'core',
			'Node',
			'single',
			'right',
		],
		componentName: 'NodeSingleRightIcon',
		package: '@atlaskit/icon-lab/core/node-single-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for whiteboard’s node single-right',
		team: 'Whiteboards',
		status: 'published',
	},
	notepad: {
		keywords: ['notepad', 'icon', 'icon-lab', 'core', 'Card', 'Note', 'Notepad', 'Memo'],
		componentName: 'NotepadIcon',
		package: '@atlaskit/icon-lab/core/notepad',
		type: 'core',
		categorization: 'multi-purpose',
		usage: "Whiteboard component 'card'",
		team: 'Confluence Whiteboards',
		status: 'published',
	},
	'page-live-doc': {
		keywords: [
			'page-live-doc',
			'pagelivedoc',
			'icon',
			'icon-lab',
			'core',
			'page',
			'live edit',
			'lightning bolt',
			'multiplayer',
			'document',
			'live doc',
		],
		componentName: 'PageLiveDocIcon',
		package: '@atlaskit/icon-lab/core/page-live-doc',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for Live Document content type.',
		team: 'Content Types',
		status: 'published',
	},
	'paint-brush': {
		keywords: [
			'paint-brush',
			'paintbrush',
			'icon',
			'icon-lab',
			'core',
			'brush',
			'appearance',
			'paint',
		],
		componentName: 'PaintBrushIcon',
		package: '@atlaskit/icon-lab/core/paint-brush',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	'paint-roller': {
		keywords: [
			'paint-roller',
			'paintroller',
			'icon',
			'icon-lab',
			'core',
			'roller',
			'background',
			'paint',
		],
		componentName: 'PaintRollerIcon',
		package: '@atlaskit/icon-lab/core/paint-roller',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	panel: {
		keywords: ['panel', 'icon', 'icon-lab', 'core', 'panel', 'card'],
		componentName: 'PanelIcon',
		package: '@atlaskit/icon-lab/core/panel',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Panel category in Confluence',
		team: 'Editor AI',
		status: 'published',
	},
	'party-popper': {
		keywords: [
			'party-popper',
			'partypopper',
			'icon',
			'icon-lab',
			'core',
			'party',
			'celebrate',
			'celebration',
			'confetti',
		],
		componentName: 'PartyPopperIcon',
		package: '@atlaskit/icon-lab/core/party-popper',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'User-selectable icon choice for calendars',
		team: 'Growth Buy',
		status: 'published',
	},
	pencil: {
		keywords: ['pencil', 'icon', 'icon-lab', 'core', 'compose', 'write', 'pen'],
		componentName: 'PencilIcon',
		package: '@atlaskit/icon-lab/core/pencil',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: compose video title in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'person-voiceover': {
		keywords: [
			'person-voiceover',
			'personvoiceover',
			'icon',
			'icon-lab',
			'core',
			'voice',
			'speak',
			'speech',
			'human',
			'user',
		],
		componentName: 'PersonVoiceoverIcon',
		package: '@atlaskit/icon-lab/core/person-voiceover',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Known uses: voiceover within Loom Variables',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'plus-circle': {
		keywords: ['plus-circle', 'pluscircle', 'icon', 'icon-lab', 'core', 'add'],
		componentName: 'PlusCircleIcon',
		package: '@atlaskit/icon-lab/core/plus-circle',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: add task in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	power: {
		keywords: ['power', 'icon', 'icon-lab', 'core', 'energy', 'turn on', 'turn off', 'power off'],
		componentName: 'PowerIcon',
		package: '@atlaskit/icon-lab/core/power',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Turn off Loom companion in Chrome extension',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'qr-code': {
		keywords: ['qr-code', 'qrcode', 'icon', 'icon-lab', 'core', 'QR code', 'barcode'],
		componentName: 'QrCodeIcon',
		package: '@atlaskit/icon-lab/core/qr-code',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for QR code experiences.',
		team: 'Assets',
		status: 'published',
	},
	'queue-pop-in': {
		keywords: [
			'queue-pop-in',
			'queuepopin',
			'icon',
			'icon-lab',
			'core',
			'Queues',
			'pop-in',
			'pop in',
			'move back to sidebar',
		],
		componentName: 'QueuePopInIcon',
		package: '@atlaskit/icon-lab/core/queue-pop-in',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for JSM queue navigation to move back to sidebar',
		team: 'JSM core capabilities',
		status: 'published',
	},
	'queue-pop-out': {
		keywords: [
			'queue-pop-out',
			'queuepopout',
			'icon',
			'icon-lab',
			'core',
			'pop-out',
			'pop out',
			'Queues',
			'Move out from sidebar',
		],
		componentName: 'QueuePopOutIcon',
		package: '@atlaskit/icon-lab/core/queue-pop-out',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for JSM queue navigation to pop out from sidebar',
		team: 'JSM core capabilities',
		status: 'published',
	},
	recur: {
		keywords: ['recur', 'icon', 'icon-lab', 'core', 'recur', 'recurring', 'repeat', 'repeating'],
		componentName: 'RecurIcon',
		package: '@atlaskit/icon-lab/core/recur',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for setting objects to recur (eg Work items, Forms)',
		team: 'Pandora',
		slackChannel: '#horizon-pandora-eng',
	},
	repeat: {
		keywords: ['repeat', 'icon', 'icon-lab', 'core', 'recur', 'recurring', 'repeat', 'repeating'],
		componentName: 'RepeatIcon',
		package: '@atlaskit/icon-lab/core/repeat',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for setting objects to recur (eg Work items, Forms)',
		team: 'Pandora',
		status: 'published',
		slackChannel: '#horizon-pandora-eng',
	},
	'reply-left': {
		keywords: [
			'reply-left',
			'replyleft',
			'icon',
			'icon-lab',
			'core',
			'arrow',
			'reply',
			'respond',
			'message',
		],
		componentName: 'ReplyLeftIcon',
		package: '@atlaskit/icon-lab/core/reply-left',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Known uses: replying to a message thread',
		team: 'Loom',
		status: 'published',
	},
	'reply-right': {
		keywords: ['reply-right', 'replyright', 'icon', 'icon-lab', 'core'],
		componentName: 'ReplyRightIcon',
		package: '@atlaskit/icon-lab/core/reply-right',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Known uses: replying to a message thread',
		team: 'Loom',
		status: 'published',
	},
	return: {
		keywords: ['return', 'icon', 'icon-lab', 'core', 'submit', 'return', 'enter', 'confirm'],
		componentName: 'ReturnIcon',
		package: '@atlaskit/icon-lab/core/return',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for submitting text fields without dedicated button, mainly AI prompts',
		team: 'Data Experiences (AVP)',
		status: 'published',
		slackChannel: '#avp-design',
	},
	'roadmaps-plan': {
		keywords: [
			'roadmaps-plan',
			'roadmapsplan',
			'icon',
			'icon-lab',
			'core',
			'roadmaps',
			'roadmap',
			'plan',
		],
		componentName: 'RoadmapsPlanIcon',
		package: '@atlaskit/icon-lab/core/roadmaps-plan',
		oldName: ['bitbucket/builds'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for roadmaps plan.',
		team: 'Design System Team',
		status: 'published',
	},
	'roadmaps-service': {
		keywords: [
			'roadmaps-service',
			'roadmapsservice',
			'icon',
			'icon-lab',
			'core',
			'roadmaps',
			'service',
			'roadmap',
		],
		componentName: 'RoadmapsServiceIcon',
		package: '@atlaskit/icon-lab/core/roadmaps-service',
		oldName: ['bitbucket/forks'],
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for roadmaps service.',
		team: 'Design System Team',
		status: 'published',
	},
	'screen-record': {
		keywords: [
			'screen-record',
			'screenrecord',
			'icon',
			'icon-lab',
			'core',
			'screen recorder',
			'camera',
			'cam bubble',
		],
		componentName: 'ScreenRecordIcon',
		package: '@atlaskit/icon-lab/core/screen-record',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Known uses: screen recorder with the cam bubble for Loom',
		team: 'Loom',
		status: 'published',
	},
	section: {
		keywords: ['section', 'icon', 'icon-lab', 'core', 'section', 'related content'],
		componentName: 'SectionIcon',
		package: '@atlaskit/icon-lab/core/section',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for section tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-diamond': {
		keywords: [
			'shape-diamond',
			'shapediamond',
			'icon',
			'icon-lab',
			'core',
			'diamond',
			'shape tool',
		],
		componentName: 'ShapeDiamondIcon',
		package: '@atlaskit/icon-lab/core/shape-diamond',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Diamond shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-ellipse': {
		keywords: [
			'shape-ellipse',
			'shapeellipse',
			'icon',
			'icon-lab',
			'core',
			'ellipse',
			'circle',
			'shape tool',
		],
		componentName: 'ShapeEllipseIcon',
		package: '@atlaskit/icon-lab/core/shape-ellipse',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Ellipse shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-parallelogram-skew-left': {
		keywords: [
			'shape-parallelogram-skew-left',
			'shapeparallelogramskewleft',
			'icon',
			'icon-lab',
			'core',
			'parallelogram',
			'skewed',
			'left',
			'shape tool',
		],
		componentName: 'ShapeParallelogramSkewLeftIcon',
		package: '@atlaskit/icon-lab/core/shape-parallelogram-skew-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Left parallelogram shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-parallelogram-skew-right': {
		keywords: [
			'shape-parallelogram-skew-right',
			'shapeparallelogramskewright',
			'icon',
			'icon-lab',
			'core',
			'parallelogram',
			'skewed',
			'shape tool',
			'right',
		],
		componentName: 'ShapeParallelogramSkewRightIcon',
		package: '@atlaskit/icon-lab/core/shape-parallelogram-skew-right',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Right parallelogram shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-rectangle': {
		keywords: [
			'shape-rectangle',
			'shaperectangle',
			'icon',
			'icon-lab',
			'core',
			'shape tool',
			'rectangle',
			'square',
		],
		componentName: 'ShapeRectangleIcon',
		package: '@atlaskit/icon-lab/core/shape-rectangle',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Rectangle shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-rectangle-rounded': {
		keywords: [
			'shape-rectangle-rounded',
			'shaperectanglerounded',
			'icon',
			'icon-lab',
			'core',
			'shape tool',
			'rectangle',
			'rounded',
			'square',
		],
		componentName: 'ShapeRectangleRoundedIcon',
		package: '@atlaskit/icon-lab/core/shape-rectangle-rounded',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Rounded rectangle shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-triangle': {
		keywords: [
			'shape-triangle',
			'shapetriangle',
			'icon',
			'icon-lab',
			'core',
			'triangle',
			'shape tool',
			'equilateral',
		],
		componentName: 'ShapeTriangleIcon',
		package: '@atlaskit/icon-lab/core/shape-triangle',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Triangle shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'shape-triangle-inverted': {
		keywords: [
			'shape-triangle-inverted',
			'shapetriangleinverted',
			'icon',
			'icon-lab',
			'core',
			'triangle',
			'shape tool',
			'equilateral',
		],
		componentName: 'ShapeTriangleInvertedIcon',
		package: '@atlaskit/icon-lab/core/shape-triangle-inverted',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Upside down triangle shape tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	signature: {
		keywords: [
			'signature',
			'icon',
			'icon-lab',
			'core',
			'signature',
			'scribble',
			'author',
			'authour',
			'sticky note',
			'post-it',
		],
		componentName: 'SignatureIcon',
		package: '@atlaskit/icon-lab/core/signature',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Toggle author visibility on sticky notes in Whiteboards.',
		team: 'Whiteboards',
		status: 'published',
	},
	'sort-options': {
		keywords: [
			'sort-options',
			'sortoptions',
			'icon',
			'icon-lab',
			'core',
			'sorting',
			'ordering',
			'alphabetization',
			'alphabetisation',
			'reordering',
		],
		componentName: 'SortOptionsIcon',
		package: '@atlaskit/icon-lab/core/sort-options',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for accessing sorting options.',
		team: 'Databases',
		status: 'published',
	},
	space: {
		keywords: ['space', 'icon', 'icon-lab', 'core'],
		componentName: 'SpaceIcon',
		package: '@atlaskit/icon-lab/core/space',
		type: 'core',
		categorization: 'single-purpose',
		usage:
			'Reserved for use for Jira Projects for now. This will replace the Jira rocket. Context: Later, it will eventually replace the Confluence Spaces icon later when Jira Projects -> Jira Spaces. This is a planned phased rollout.',
		team: 'Jira',
		status: 'published',
		slackChannel: '#jira-design-system-figma-libraries',
	},
	speedometer: {
		keywords: ['speedometer', 'icon', 'icon-lab', 'core', 'speed', 'performance', 'dial', 'fast'],
		componentName: 'SpeedometerIcon',
		package: '@atlaskit/icon-lab/core/speedometer',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: video is set to a faster playback speed',
		team: 'Loom',
	},
	'speedometer-left': {
		keywords: [
			'speedometer-left',
			'speedometerleft',
			'icon',
			'icon-lab',
			'core',
			'speed',
			'performance',
			'dial',
			'slow',
		],
		componentName: 'SpeedometerLeftIcon',
		package: '@atlaskit/icon-lab/core/speedometer-left',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: video is set to a slower playback speed',
		team: 'Loom',
		status: 'published',
	},
	'speedometer-right': {
		keywords: [
			'speedometer-right',
			'speedometerright',
			'icon',
			'icon-lab',
			'core',
			'speed',
			'performance',
			'dial',
			'fast',
		],
		componentName: 'SpeedometerRightIcon',
		package: '@atlaskit/icon-lab/core/speedometer-right',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: video is set to a faster playback speed',
		team: 'Loom',
		status: 'published',
	},
	'speedometer-up': {
		keywords: [
			'speedometer-up',
			'speedometerup',
			'icon',
			'icon-lab',
			'core',
			'speed',
			'performance',
			'dial',
		],
		componentName: 'SpeedometerUpIcon',
		package: '@atlaskit/icon-lab/core/speedometer-up',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: video is set a normal playback speed',
		team: 'Loom',
		status: 'published',
	},
	'split-parallel': {
		keywords: ['split-parallel', 'splitparallel', 'icon', 'icon-lab', 'core', 'router', 'branches'],
		componentName: 'SplitParallelIcon',
		package: '@atlaskit/icon-lab/core/split-parallel',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for split parallel branches in Automation',
		team: 'Automation',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	stamp: {
		keywords: ['stamp', 'icon', 'icon-lab', 'core', 'stamp', 'rubber stamp', 'handstamp'],
		componentName: 'StampIcon',
		package: '@atlaskit/icon-lab/core/stamp',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for stamp tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	sticker: {
		keywords: ['sticker', 'icon', 'icon-lab', 'core', 'sticker', 'peel-off'],
		componentName: 'StickerIcon',
		package: '@atlaskit/icon-lab/core/sticker',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for sticker tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-curvature-curved': {
		keywords: [
			'stroke-curvature-curved',
			'strokecurvaturecurved',
			'icon',
			'icon-lab',
			'core',
			'line tool',
			'shape property',
			'stroke style',
			'border',
			'smooth',
		],
		componentName: 'StrokeCurvatureCurvedIcon',
		package: '@atlaskit/icon-lab/core/stroke-curvature-curved',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for curved line type setting for line tool in Whiteboards.',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-curvature-dynamic': {
		keywords: [
			'stroke-curvature-dynamic',
			'strokecurvaturedynamic',
			'icon',
			'icon-lab',
			'core',
			'line tool',
			'shape property',
			'stroke style',
			'border',
		],
		componentName: 'StrokeCurvatureDynamicIcon',
		package: '@atlaskit/icon-lab/core/stroke-curvature-dynamic',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for dynamic line type setting for line tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-curvature-straight': {
		keywords: [
			'stroke-curvature-straight',
			'strokecurvaturestraight',
			'icon',
			'icon-lab',
			'core',
			'line tool',
			'shape property',
			'stroke style',
			'sharp corners',
			'border',
		],
		componentName: 'StrokeCurvatureStraightIcon',
		package: '@atlaskit/icon-lab/core/stroke-curvature-straight',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for straight line type setting for line tool in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-properties': {
		keywords: [
			'stroke-properties',
			'strokeproperties',
			'icon',
			'icon-lab',
			'core',
			'stroke properties',
			'border options',
		],
		componentName: 'StrokePropertiesIcon',
		package: '@atlaskit/icon-lab/core/stroke-properties',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for stroke properties in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-style-dashed': {
		keywords: [
			'stroke-style-dashed',
			'strokestyledashed',
			'icon',
			'icon-lab',
			'core',
			'border',
			'stroke',
			'border style',
			'dashed',
			'outline',
		],
		componentName: 'StrokeStyleDashedIcon',
		package: '@atlaskit/icon-lab/core/stroke-style-dashed',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing dashed stroke and border styles.',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-style-dotted': {
		keywords: [
			'stroke-style-dotted',
			'strokestyledotted',
			'icon',
			'icon-lab',
			'core',
			'border',
			'stroke',
			'border style',
			'dotted',
			'outline',
		],
		componentName: 'StrokeStyleDottedIcon',
		package: '@atlaskit/icon-lab/core/stroke-style-dotted',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing dotted stroke and border styles.',
		team: 'Whiteboards',
		status: 'published',
	},
	'stroke-style-solid': {
		keywords: [
			'stroke-style-solid',
			'strokestylesolid',
			'icon',
			'icon-lab',
			'core',
			'border',
			'stroke',
			'border style',
			'solid',
			'outline',
		],
		componentName: 'StrokeStyleSolidIcon',
		package: '@atlaskit/icon-lab/core/stroke-style-solid',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing solid stroke and border styles.',
		team: 'Whiteboards',
		status: 'published',
	},
	'suggested-edit': {
		keywords: [
			'suggested-edit',
			'suggestededit',
			'icon',
			'icon-lab',
			'core',
			'AI',
			'suggestion',
			'suggested edit',
			'suggest',
			'generative',
		],
		componentName: 'SuggestedEditIcon',
		package: '@atlaskit/icon-lab/core/suggested-edit',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for suggestions powered by Atlassian Intelligence',
		team: 'Editor AI',
		status: 'published',
	},
	sunset: {
		keywords: [
			'sunset',
			'icon',
			'icon-lab',
			'core',
			'sun',
			'horizon',
			'farewell',
			'goodbye',
			'sky',
			'farewell feed',
		],
		componentName: 'SunsetIcon',
		package: '@atlaskit/icon-lab/core/sunset',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Farewell feed in hello.',
		team: 'confluence insights',
		status: 'published',
	},
	swap: {
		keywords: [
			'swap',
			'icon',
			'icon-lab',
			'core',
			'Swap',
			'Jira status',
			'Whiteboards',
			'Smart section',
		],
		componentName: 'SwapIcon',
		package: '@atlaskit/icon-lab/core/swap',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Swapping between Jira tickets in Whiteboard smart section',
		team: 'Whiteboards',
		status: 'published',
	},
	'switch-case': {
		keywords: [
			'switch-case',
			'switchcase',
			'icon',
			'icon-lab',
			'core',
			'conditions',
			'if',
			'else',
			'else if',
		],
		componentName: 'SwitchCaseIcon',
		package: '@atlaskit/icon-lab/core/switch-case',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for switch case conditions in automation',
		team: 'Automations',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	sync: {
		keywords: [
			'sync',
			'icon',
			'icon-lab',
			'core',
			'sync',
			'synch',
			'synchronise',
			'synchronize',
			'saving',
			'arrows',
		],
		componentName: 'SyncIcon',
		package: '@atlaskit/icon-lab/core/sync',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for syncing changes and save operations.',
		team: 'Content Types',
		status: 'published',
	},
	tab: {
		keywords: ['tab', 'icon', 'icon-lab', 'core', 'key', 'tab'],
		componentName: 'TabIcon',
		package: '@atlaskit/icon-lab/core/tab',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for representing tab key.',
		team: 'Design System Team',
		status: 'published',
		slackChannel: '#icon-contributions',
	},
	'takeout-container': {
		keywords: [
			'takeout-container',
			'takeoutcontainer',
			'icon',
			'icon-lab',
			'core',
			'takeout',
			'food',
			'takeaway',
		],
		componentName: 'TakeoutContainerIcon',
		package: '@atlaskit/icon-lab/core/takeout-container',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known usages: Alternative option for food emoji category.',
		team: 'Design System Team',
		status: 'published',
	},
	talent: {
		keywords: ['talent', 'icon', 'icon-lab', 'core', 'talent', 'person', 'star'],
		componentName: 'TalentIcon',
		package: '@atlaskit/icon-lab/core/talent',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for functional representation of Talent app',
		team: 'Talent',
		status: 'published',
	},
	template: {
		keywords: ['template', 'icon', 'icon-lab', 'core', 'template', 'templates'],
		componentName: 'TemplateIcon',
		package: '@atlaskit/icon-lab/core/template',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Used for templates in Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-audio-transcript': {
		keywords: [
			'text-audio-transcript',
			'textaudiotranscript',
			'icon',
			'icon-lab',
			'core',
			'microphone',
			'script',
			'paragraph',
		],
		componentName: 'TextAudioTranscriptIcon',
		package: '@atlaskit/icon-lab/core/text-audio-transcript',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for viewing transcript in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'text-bold-italic': {
		keywords: [
			'text-bold-italic',
			'textbolditalic',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'Font',
			'Bold',
			'Italic',
		],
		componentName: 'TextBoldItalicIcon',
		package: '@atlaskit/icon-lab/core/text-bold-italic',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold text with italic',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-italic-strikethrough': {
		keywords: [
			'text-bold-italic-strikethrough',
			'textbolditalicstrikethrough',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'strikethrough',
			'Bold',
			'Italic',
		],
		componentName: 'TextBoldItalicStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-bold-italic-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for italic bold text with a strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-italic-underline': {
		keywords: [
			'text-bold-italic-underline',
			'textbolditalicunderline',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'Font',
			'Bold',
			'Italic',
			'Underline',
		],
		componentName: 'TextBoldItalicUnderlineIcon',
		package: '@atlaskit/icon-lab/core/text-bold-italic-underline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold italic text with underline',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-italic-underline-strikethrough': {
		keywords: [
			'text-bold-italic-underline-strikethrough',
			'textbolditalicunderlinestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'text formatting',
			'bold',
			'underline',
			'strikethrough',
			'italic',
		],
		componentName: 'TextBoldItalicUnderlineStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-bold-italic-underline-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold italic text with underline and strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-strikethrough': {
		keywords: [
			'text-bold-strikethrough',
			'textboldstrikethrough',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'Bold',
			'strikethrough',
		],
		componentName: 'TextBoldStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-bold-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold strikethrough text',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-underline': {
		keywords: [
			'text-bold-underline',
			'textboldunderline',
			'icon',
			'icon-lab',
			'core',
			'bold',
			'underline',
			'text formatting',
		],
		componentName: 'TextBoldUnderlineIcon',
		package: '@atlaskit/icon-lab/core/text-bold-underline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold text with underline',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-bold-underline-strikethrough': {
		keywords: [
			'text-bold-underline-strikethrough',
			'textboldunderlinestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'text formatting',
			'bold',
			'strikethrough',
			'underline',
		],
		componentName: 'TextBoldUnderlineStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-bold-underline-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for bold text with underline and strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-conclusion-add': {
		keywords: [
			'text-conclusion-add',
			'textconclusionadd',
			'icon',
			'icon-lab',
			'core',
			'text',
			'conclusion',
			'AI',
			'generative',
		],
		componentName: 'TextConclusionAddIcon',
		package: '@atlaskit/icon-lab/core/text-conclusion-add',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Confluence Editor AI action for generating a conclusion',
		team: 'Editor AI',
		status: 'published',
	},
	'text-heading-five': {
		keywords: [
			'text-heading-five',
			'textheadingfive',
			'icon',
			'icon-lab',
			'core',
			'heading 5',
			'H5',
			'text style',
		],
		componentName: 'TextHeadingFiveIcon',
		package: '@atlaskit/icon-lab/core/text-heading-five',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H5 / Heading 5 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'text-heading-four': {
		keywords: [
			'text-heading-four',
			'textheadingfour',
			'icon',
			'icon-lab',
			'core',
			'heading 4',
			'H4',
			'text style',
		],
		componentName: 'TextHeadingFourIcon',
		package: '@atlaskit/icon-lab/core/text-heading-four',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H4 / Heading 4 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'text-heading-one': {
		keywords: [
			'text-heading-one',
			'textheadingone',
			'icon',
			'icon-lab',
			'core',
			'Heading 1',
			'H1',
			'text style',
		],
		componentName: 'TextHeadingOneIcon',
		package: '@atlaskit/icon-lab/core/text-heading-one',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H1 / Heading 1 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#Editor-design',
	},
	'text-heading-six': {
		keywords: [
			'text-heading-six',
			'textheadingsix',
			'icon',
			'icon-lab',
			'core',
			'heading 6',
			'H6',
			'text style',
		],
		componentName: 'TextHeadingSixIcon',
		package: '@atlaskit/icon-lab/core/text-heading-six',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H6 / Heading 6 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'text-heading-three': {
		keywords: [
			'text-heading-three',
			'textheadingthree',
			'icon',
			'icon-lab',
			'core',
			'heading 3',
			'H3',
			'text style',
		],
		componentName: 'TextHeadingThreeIcon',
		package: '@atlaskit/icon-lab/core/text-heading-three',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H3 / Heading 3 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'text-heading-two': {
		keywords: [
			'text-heading-two',
			'textheadingtwo',
			'icon',
			'icon-lab',
			'core',
			'Heading 2',
			'H2',
			'text style',
		],
		componentName: 'TextHeadingTwoIcon',
		package: '@atlaskit/icon-lab/core/text-heading-two',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for H2 / Heading 2 text style',
		team: 'Editor design',
		status: 'published',
		slackChannel: '#editor-design',
	},
	'text-introduction-add': {
		keywords: [
			'text-introduction-add',
			'textintroductionadd',
			'icon',
			'icon-lab',
			'core',
			'text',
			'introduction',
			'AI',
			'generative',
		],
		componentName: 'TextIntroductionAddIcon',
		package: '@atlaskit/icon-lab/core/text-introduction-add',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Confluence Editor AI action for generating an introduction',
		team: 'Editor AI',
		status: 'published',
	},
	'text-italic-strikethrough': {
		keywords: [
			'text-italic-strikethrough',
			'textitalicstrikethrough',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'Font',
			'Italic',
			'Strikethrough',
		],
		componentName: 'TextItalicStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-italic-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for italic text with strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-italic-underline': {
		keywords: [
			'text-italic-underline',
			'textitalicunderline',
			'icon',
			'icon-lab',
			'core',
			'Text',
			'Type',
			'Font',
			'Underline',
			'Italic',
		],
		componentName: 'TextItalicUnderlineIcon',
		package: '@atlaskit/icon-lab/core/text-italic-underline',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for italic text with underline',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-italic-underline-strikethrough': {
		keywords: [
			'text-italic-underline-strikethrough',
			'textitalicunderlinestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'text formatting',
			'italic',
			'underline',
			'strikethrough',
		],
		componentName: 'TextItalicUnderlineStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-italic-underline-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for italic text with underline and strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	'text-lengthen': {
		keywords: [
			'text-lengthen',
			'textlengthen',
			'icon',
			'icon-lab',
			'core',
			'text',
			'lengthen',
			'expand',
			'AI',
			'generative',
		],
		componentName: 'TextLengthenIcon',
		package: '@atlaskit/icon-lab/core/text-lengthen',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Lengthening text content with Atlassian Intelligence',
		team: 'Editor AI',
		status: 'published',
	},
	'text-paragraph': {
		keywords: [
			'text-paragraph',
			'textparagraph',
			'icon',
			'icon-lab',
			'core',
			'text',
			'paragraph break',
			'AI',
			'generative',
			'reformat',
		],
		componentName: 'TextParagraphIcon',
		package: '@atlaskit/icon-lab/core/text-paragraph',
		type: 'core',
		categorization: 'multi-purpose',
		usage:
			'Confluence Editor AI action for reformatting paragraphs into multiple shorter paragraphs',
		team: 'Editor AI',
		status: 'published',
	},
	'text-rephrase': {
		keywords: [
			'text-rephrase',
			'textrephrase',
			'icon',
			'icon-lab',
			'core',
			'text',
			'rephrase',
			'AI',
			'generative',
		],
		componentName: 'TextRephraseIcon',
		package: '@atlaskit/icon-lab/core/text-rephrase',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Confluence Editor AI action for rephrasing text',
		team: 'Editor AI',
		status: 'published',
	},
	'text-underline-strikethrough': {
		keywords: [
			'text-underline-strikethrough',
			'textunderlinestrikethrough',
			'icon',
			'icon-lab',
			'core',
			'text',
			'type',
			'font',
			'strikethrough',
			'underline',
		],
		componentName: 'TextUnderlineStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/text-underline-strikethrough',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for underline text with a strikethrough',
		team: 'Whiteboards',
		status: 'published',
	},
	ticket: {
		keywords: ['ticket', 'icon', 'icon-lab', 'core', 'stub', 'ticket'],
		componentName: 'TicketIcon',
		package: '@atlaskit/icon-lab/core/ticket',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	tidy: {
		keywords: ['tidy', 'icon', 'icon-lab', 'core', 'Tidy', 'Clean up', 'Tidy up', 'Grid'],
		componentName: 'TidyIcon',
		package: '@atlaskit/icon-lab/core/tidy',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Tidy up objects on Whiteboards',
		team: 'Whiteboards',
		status: 'published',
	},
	variable: {
		keywords: ['variable', 'icon', 'icon-lab', 'core'],
		componentName: 'VariableIcon',
		package: '@atlaskit/icon-lab/core/variable',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for Variables functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'vehicle-airplane': {
		keywords: [
			'vehicle-airplane',
			'vehicleairplane',
			'icon',
			'icon-lab',
			'core',
			'plane',
			'airplane',
			'aeroplane',
			'travel',
			'vehicle',
		],
		componentName: 'VehicleAirplaneIcon',
		package: '@atlaskit/icon-lab/core/vehicle-airplane',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'User-selectable icon choice for calendars',
		team: 'Growth Buy',
		status: 'published',
	},
	'vehicle-train': {
		keywords: [
			'vehicle-train',
			'vehicletrain',
			'icon',
			'icon-lab',
			'core',
			'transportation',
			'vehicle',
			'car',
		],
		componentName: 'VehicleTrainIcon',
		package: '@atlaskit/icon-lab/core/vehicle-train',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known usages: Alternative option for transport emoji category.',
		team: 'Design System Team',
		status: 'published',
	},
	'video-captions-stylized': {
		keywords: [
			'video-captions-stylized',
			'videocaptionsstylized',
			'icon',
			'icon-lab',
			'core',
			'CC',
			'subtitles',
		],
		componentName: 'VideoCaptionsStylizedIcon',
		package: '@atlaskit/icon-lab/core/video-captions-stylized',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for stylized captions functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-clip': {
		keywords: [
			'video-clip',
			'videoclip',
			'icon',
			'icon-lab',
			'core',
			'film',
			'film strip',
			'video',
			'media',
			'file',
		],
		componentName: 'VideoClipIcon',
		package: '@atlaskit/icon-lab/core/video-clip',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: add clip in Loom video editor',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-closed-captions': {
		keywords: [
			'video-closed-captions',
			'videoclosedcaptions',
			'icon',
			'icon-lab',
			'core',
			'CC',
			'closed captions',
			'subtitles',
		],
		componentName: 'VideoClosedCaptionsIcon',
		package: '@atlaskit/icon-lab/core/video-closed-captions',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for enabling closed captions for videos.',
		team: 'Loom',
		status: 'published',
	},
	'video-closed-captions-filled': {
		keywords: [
			'video-closed-captions-filled',
			'videoclosedcaptionsfilled',
			'icon',
			'icon-lab',
			'core',
		],
		componentName: 'VideoClosedCaptionsFilledIcon',
		package: '@atlaskit/icon-lab/core/video-closed-captions-filled',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved to show that closed captions are enabled for videos.',
		team: 'Loom',
		status: 'published',
	},
	'video-hd': {
		keywords: ['video-hd', 'videohd', 'icon', 'icon-lab', 'core', 'high definition', 'hi def'],
		componentName: 'VideoHdIcon',
		package: '@atlaskit/icon-lab/core/video-hd',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved for enabling high definition videos',
		team: 'Atlassian Design System',
		status: 'published',
	},
	'video-hd-filled': {
		keywords: [
			'video-hd-filled',
			'videohdfilled',
			'icon',
			'icon-lab',
			'core',
			'high definition',
			'hi def',
		],
		componentName: 'VideoHdFilledIcon',
		package: '@atlaskit/icon-lab/core/video-hd-filled',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Single purpose - Reserved to show that high definition is enabled for videos',
		team: 'Atlassian Design System',
		status: 'published',
	},
	'video-miniplayer': {
		keywords: [
			'video-miniplayer',
			'videominiplayer',
			'icon',
			'icon-lab',
			'core',
			'picture in picture',
			'PIP',
		],
		componentName: 'VideoMiniplayerIcon',
		package: '@atlaskit/icon-lab/core/video-miniplayer',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for picture-in-picture functionality for videos.',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-overlay-tools': {
		keywords: [
			'video-overlay-tools',
			'videooverlaytools',
			'icon',
			'icon-lab',
			'core',
			'overlays',
			'video',
			'add text',
			'add arrow',
			'add box',
		],
		componentName: 'VideoOverlayToolsIcon',
		package: '@atlaskit/icon-lab/core/video-overlay-tools',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for overlays over a video.',
		team: 'Loom',
		status: 'published',
	},
	'video-play-circle': {
		keywords: [
			'video-play-circle',
			'videoplaycircle',
			'icon',
			'icon-lab',
			'core',
			'video',
			'start',
		],
		componentName: 'VideoPlayCircleIcon',
		package: '@atlaskit/icon-lab/core/video-play-circle',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: play video in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-record': {
		keywords: [
			'video-record',
			'videorecord',
			'icon',
			'icon-lab',
			'core',
			'live',
			'circle',
			'ellipse',
			'recording',
		],
		componentName: 'VideoRecordIcon',
		package: '@atlaskit/icon-lab/core/video-record',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for when a video is recording',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-rewind-overlay': {
		keywords: [
			'video-rewind-overlay',
			'videorewindoverlay',
			'icon',
			'icon-lab',
			'core',
			'rewind back',
		],
		componentName: 'VideoRewindOverlayIcon',
		package: '@atlaskit/icon-lab/core/video-rewind-overlay',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for rewinding videos.',
		team: 'Loom',
		status: 'published',
	},
	'video-skip-backward-five': {
		keywords: [
			'video-skip-backward-five',
			'videoskipbackwardfive',
			'icon',
			'icon-lab',
			'core',
			'video',
			'media',
			'skip',
			'forward',
			'5',
			'seconds',
		],
		componentName: 'VideoSkipBackwardFiveIcon',
		package: '@atlaskit/icon-lab/core/video-skip-backward-five',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content backward by 5 seconds.',
		team: 'Loom',
		status: 'published',
	},
	'video-skip-forward-five': {
		keywords: [
			'video-skip-forward-five',
			'videoskipforwardfive',
			'icon',
			'icon-lab',
			'core',
			'video',
			'media',
			'skip',
			'forward',
			'5',
			'seconds',
		],
		componentName: 'VideoSkipForwardFiveIcon',
		package: '@atlaskit/icon-lab/core/video-skip-forward-five',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for skipping video content forward by 5 seconds.',
		team: 'Loom',
		status: 'published',
	},
	'video-strikethrough': {
		keywords: [
			'video-strikethrough',
			'videostrikethrough',
			'icon',
			'icon-lab',
			'core',
			'video',
			'no video',
			'camera off',
			'off',
		],
		componentName: 'VideoStrikethroughIcon',
		package: '@atlaskit/icon-lab/core/video-strikethrough',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Reserved for no video input',
		team: 'Loom',
		status: 'published',
	},
	'video-theater-mode': {
		keywords: [
			'video-theater-mode',
			'videotheatermode',
			'icon',
			'icon-lab',
			'core',
			'theater mode',
			'view',
			'viewport',
			'video',
			'wide',
		],
		componentName: 'VideoTheaterModeIcon',
		package: '@atlaskit/icon-lab/core/video-theater-mode',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for theater mode functionality within video player.',
		team: 'Loom',
		status: 'published',
	},
	'video-timeline-clip-split': {
		keywords: [
			'video-timeline-clip-split',
			'videotimelineclipsplit',
			'icon',
			'icon-lab',
			'core',
			'split',
			'clip',
			'edit',
			'enhance',
			'video',
			'timeline',
			'trim',
		],
		componentName: 'VideoTimelineClipSplitIcon',
		package: '@atlaskit/icon-lab/core/video-timeline-clip-split',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for split clip functionality in Loom.',
		team: 'Loom',
		status: 'published',
	},
	'video-timeline-editor': {
		keywords: [
			'video-timeline-editor',
			'videotimelineeditor',
			'icon',
			'icon-lab',
			'core',
			'track',
			'edit',
			'video',
		],
		componentName: 'VideoTimelineEditorIcon',
		package: '@atlaskit/icon-lab/core/video-timeline-editor',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for timeline editor functionality in Loom',
		team: 'Loom',
		status: 'published',
		slackChannel: '#feedback-loom-vizrefresh',
	},
	'video-watch-later-remove': {
		keywords: [
			'video-watch-later-remove',
			'videowatchlaterremove',
			'icon',
			'icon-lab',
			'core',
			'bookmark',
			'x',
			'remove',
			'cross',
		],
		componentName: 'VideoWatchLaterRemoveIcon',
		package: '@atlaskit/icon-lab/core/video-watch-later-remove',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for watch later functionality in Loom',
		team: 'Loom',
		status: 'published',
	},
	'video-watch-later-saved': {
		keywords: [
			'video-watch-later-saved',
			'videowatchlatersaved',
			'icon',
			'icon-lab',
			'core',
			'bookmark',
			'check',
			'saved',
		],
		componentName: 'VideoWatchLaterSavedIcon',
		package: '@atlaskit/icon-lab/core/video-watch-later-saved',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for watch later functionality in Loom',
		team: 'Loom',
		status: 'published',
	},
	'view-type-board-home': {
		keywords: [
			'view-type-board-home',
			'viewtypeboardhome',
			'icon',
			'icon-lab',
			'core',
			'board view',
			'databases',
			'home',
			'default view',
		],
		componentName: 'ViewTypeBoardHomeIcon',
		package: '@atlaskit/icon-lab/core/view-type-board-home',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Home view for database board layout',
		team: 'Databases',
		status: 'published',
	},
	'view-type-card-home': {
		keywords: [
			'view-type-card-home',
			'viewtypecardhome',
			'icon',
			'icon-lab',
			'core',
			'card view',
			'databases',
			'home',
			'default view',
		],
		componentName: 'ViewTypeCardHomeIcon',
		package: '@atlaskit/icon-lab/core/view-type-card-home',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Home view for database card layout',
		team: 'Databases',
		status: 'published',
	},
	'view-type-table-home': {
		keywords: [
			'view-type-table-home',
			'viewtypetablehome',
			'icon',
			'icon-lab',
			'core',
			'table view',
			'databases',
			'home',
			'default view',
		],
		componentName: 'ViewTypeTableHomeIcon',
		package: '@atlaskit/icon-lab/core/view-type-table-home',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Home view for database table layout',
		team: 'Databases',
		status: 'published',
	},
	vulnerability: {
		keywords: [
			'vulnerability',
			'icon',
			'icon-lab',
			'core',
			'vulnerability',
			'security',
			'alert',
			'warning',
		],
		componentName: 'VulnerabilityIcon',
		package: '@atlaskit/icon-lab/core/vulnerability',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for security vulnerabilities.',
		team: 'Automation',
		status: 'published',
	},
	wallet: {
		keywords: ['wallet', 'icon', 'icon-lab', 'core', 'money', 'sales', 'payment', 'wallet'],
		componentName: 'WalletIcon',
		package: '@atlaskit/icon-lab/core/wallet',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
	'warning-outline': {
		keywords: [
			'warning-outline',
			'warningoutline',
			'icon',
			'icon-lab',
			'core',
			'warning',
			'exclamation',
			'overdue',
			'past due',
			'late',
		],
		componentName: 'WarningOutlineIcon',
		package: '@atlaskit/icon-lab/core/warning-outline',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Low-impact warnings. Overdue dates in Atlassian Home.',
		team: 'Atlassian Home',
		status: 'published',
	},
	webhook: {
		keywords: ['webhook', 'icon', 'icon-lab', 'core', 'api', 'callback', 'payload'],
		componentName: 'WebhookIcon',
		package: '@atlaskit/icon-lab/core/webhook',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for using a webhook to send data between applications via HTTP',
		team: 'Automation',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	'welcome-feed': {
		keywords: [
			'welcome-feed',
			'welcomefeed',
			'icon',
			'icon-lab',
			'core',
			'welcome',
			'hello',
			'blog',
			'smile',
			'intro',
		],
		componentName: 'WelcomeFeedIcon',
		package: '@atlaskit/icon-lab/core/welcome-feed',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Known uses: Intro blog feed picker on Hello',
		team: 'Insights',
		status: 'published',
		slackChannel: '#cc-insights-and-reporting',
	},
	'work-item-add': {
		keywords: [
			'work-item-add',
			'workitemadd',
			'icon',
			'icon-lab',
			'core',
			'issue',
			'work item',
			'work type',
			'add',
			'plus',
			'create',
		],
		componentName: 'WorkItemAddIcon',
		package: '@atlaskit/icon-lab/core/work-item-add',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for adding or creating a new work item.',
		team: 'Jira',
		status: 'published',
	},
	'workflow-controls': {
		keywords: [
			'workflow-controls',
			'workflowcontrols',
			'icon',
			'icon-lab',
			'core',
			'orchestration',
		],
		componentName: 'WorkflowControlsIcon',
		package: '@atlaskit/icon-lab/core/workflow-controls',
		type: 'core',
		categorization: 'single-purpose',
		usage: 'Reserved for workflow controls in automation',
		team: 'Automation',
		status: 'published',
		slackChannel: '#help-automation-design',
	},
	wrench: {
		keywords: ['wrench', 'icon', 'icon-lab', 'core', 'wrench', 'spanner', 'tool'],
		componentName: 'WrenchIcon',
		package: '@atlaskit/icon-lab/core/wrench',
		type: 'core',
		categorization: 'multi-purpose',
		usage: 'Multi purpose',
		team: 'Design System Team',
		status: 'published',
	},
};

export default metadata;
