import { type Groups, type ThemeColorModes } from '@atlaskit/tokens';

import type { TokenGroup } from './grouped-tokens';
import type {
	ActiveTheme,
	TokenListColumns,
	TransformedTokenGrouped,
	TransformedTokenMerged,
} from './types';

export const filterTokens = <T extends TransformedTokenGrouped | TransformedTokenMerged>(
	list: Array<T>,
	filters: {
		showStates?: string[];
	},
): Array<T> =>
	list.filter(({ attributes }) =>
		filters?.showStates?.some((state) => state === attributes?.state),
	);

export const filterGroups = (
	groups: TokenGroup[],
	filters: {
		showStates?: string[];
	},
): TokenGroup[] =>
	groups.reduce((newGroups: TokenGroup[], currentGroup) => {
		const newGroup = {
			...currentGroup,
			tokens: filterTokens(currentGroup.tokens, filters),
			subgroups: currentGroup.subgroups?.reduce((newSubgroups: TokenGroup[], currentSubgroup) => {
				const newSubgroup = {
					...currentSubgroup,
					tokens: filterTokens(currentSubgroup.tokens, filters),
				};

				if (newSubgroup.tokens.length > 0) {
					newSubgroups.push(newSubgroup);
				}

				return newSubgroups;
			}, []),
		};

		if (newGroup.tokens.length > 0 || (newGroup?.subgroups && newGroup.subgroups.length > 0)) {
			newGroups.push(newGroup);
		}

		return newGroups;
	}, []);

/**
 * A reducer that finds how many tokens are in a collection of groups 🥲 very silly
 */
export const getNumberOfTokensInGroups = (groups: TokenGroup[]) =>
	groups.reduce((count, group) => {
		const topLevel = group.tokens.length;

		const topLevelExtensions = group.tokens.reduce((extensionCount, token) => {
			const inExtensions = token.extensions ? token.extensions.length : 0;

			return extensionCount + inExtensions;
		}, 0);

		const inSubgroups = group.subgroups
			? group.subgroups.reduce((subgroupCount, subgroup) => {
					const subgroupExtensions = subgroup.tokens.reduce((extensionCount, token) => {
						const inExtensions = token.extensions ? token.extensions.length : 0;

						return extensionCount + inExtensions;
					}, 0);

					return subgroupCount + subgroup.tokens.length + subgroupExtensions;
				}, 0)
			: 0;

		return count + topLevel + topLevelExtensions + inSubgroups;
	}, 0);

/**
 * Returns an array of column names to display corresponding to the token group provided
 */
export const getTokenListColumns = (group?: Groups): TokenListColumns => {
	switch (group) {
		case 'shape':
			return [{ label: 'Token and description' }, { label: 'Value' }];
		case 'spacing':
			return [{ label: 'Token' }, { label: 'Value' }];
		case 'typography':
			return [
				{ label: 'Token and description' },
				{
					label: 'Legacy',
					forceTheme: { typography: 'typography-adg3' },
					width: 'extra-large',
				},
				{
					label: 'Refreshed',
					forceTheme: { typography: 'typography-refreshed' },
					width: 'extra-large',
				},
			];
		case 'fontWeight':
		case 'fontFamily':
			return [{ label: 'Token and description' }, { label: 'Preview', width: 'extra-large' }];
		default:
			break;
	}

	const width = group === 'shadow' ? 'extra-large' : 'large';
	return [
		{ label: 'Token and description' },
		{ label: 'Light value', forceTheme: { colorMode: 'light' }, width },
		{ label: 'Dark value', forceTheme: { colorMode: 'dark' }, width },
	];
};

export const normaliseThemeColorMode = (
	theme: ActiveTheme | undefined,
): Exclude<ThemeColorModes, 'auto'> => (theme?.colorMode ? theme.colorMode : 'light');
