import React, { useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import type { SearchBoxProvided } from 'react-instantsearch-core';
import { connectSearchBox } from 'react-instantsearch-dom';

import SearchIcon from '@atlaskit/icon/glyph/search';
import { Box, xcss } from '@atlaskit/primitives';
import Textfield from '@atlaskit/textfield';
import { N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import VisuallyHidden from '@atlaskit/visually-hidden';

const SEARCH_DEBOUNCE_MS = 350;

const searchWrapperStyles = xcss({
	flex: '1 1 auto',

	// @ts-expect-error hardcode input font size
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		// Safari zooms if the input has smaller text
		// This needs to be wrapped with token() since xcss types only work for top level properties
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/use-latest-xcss-syntax-typography -- Ignored via go/DSP-18766
		font: token('font.body.large'),
	},
});

type SearchInputProps = {
	testId?: string;
} & SearchBoxProvided;

/**
 * __Search input__
 */
const SearchInput = connectSearchBox<SearchInputProps>(({ refine, testId }) => {
	const [inputValue, setInputValue] = useState('');

	const handleChange = useMemo(
		() =>
			debounce((inputValue: string) => {
				refine(inputValue);
			}, SEARCH_DEBOUNCE_MS),
		[refine],
	);

	return (
		<Box xcss={searchWrapperStyles}>
			<VisuallyHidden>
				<label htmlFor="search-input">Search</label>
			</VisuallyHidden>
			<Textfield
				testId={testId ? `${testId}-search-input` : undefined}
				id="search-input"
				onChange={(e) => {
					setInputValue(e.currentTarget.value);
					handleChange(e.currentTarget.value);
				}}
				placeholder="Search the Atlassian Design System"
				value={inputValue}
				elemBeforeInput={
					<Box as="span" paddingInlineStart="space.100">
						<SearchIcon label="" primaryColor={token('color.icon', N200)} />
					</Box>
				}
				autoComplete="off"
			/>
		</Box>
	);
});

export default SearchInput;
