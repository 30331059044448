import { useMemo } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import type { NavigationItem } from '@af/design-system-docs-ui';

// @ts-expect-error -- untyped JS file
import { PAGE_ORDER, WORKAROUND_PAGE_ID } from '../../../constellation.config';

import { getNavigationItems } from './navigation-items';
import { ContentfulData } from './types';

/**
 * __Use side navigation__
 *
 * Statically queries all side navigation pages from Gatsby and
 * returns an organized hierarchy of pages.
 */
const useSideNavigation = (): NavigationItem[] => {
	const data: ContentfulData = useStaticQuery(graphql`
		query ContentfulData {
			allContentfulGuideline(
				sort: { title: ASC }
				filter: {
					category: { glob: "?(Get started|Tokens|Brand|Foundations|Content|Resources)" }
					slug: { ne: null }
				}
			) {
				nodes {
					contentful_id
					__typename
					slug
					title
					contentfulparent {
						slug
					}
					status
					readyForPreview
					category
				}
			}
			allWorkspaceInfo(sort: { title: ASC }) {
				categories: group(field: { category: SELECT }) {
					name: fieldValue
					packages: nodes {
						slug
						id
						title
						status
						pages
						subPages
					}
				}
			}
			allSitePage(filter: { path: { regex: "/^/components/[^$]/" } }) {
				componentPages: nodes {
					path
					pageContext
				}
			}
		}
	`);

	const navigationItems = useMemo(() => {
		return getNavigationItems(data);
	}, [data]);

	return navigationItems;
};

export default useSideNavigation;
