import React, { ReactNode } from 'react';

import { Table, TBody, TD, TH, THead, TR } from '@atlaskit/table/primitives';

import HorizontalScrollContainer from '../../horizontal-scroll-container';

import {
	AlternateGermanDoubleS,
	CapitalG,
	FlatTop3,
	LowercaseL,
	MonoLigatures,
	MonoSlashedZero,
	SingleStoryA,
	SlashedZero,
	SquarePunctuationQuotes,
	TabularFigures,
	UppercaseI,
} from './font-feature-previews';

type FeatureValue = {
	preview: ReactNode;
	description: string;
	notes: string;
};

const fontFeaturesMapSans: FeatureValue[] = [
	{
		preview: <LowercaseL />,
		description: 'Lower-case L with tail',
		notes: 'Use always',
	},
	{
		preview: <UppercaseI />,
		description: 'Upper-case i with serif',
		notes: 'Use always',
	},
	{
		preview: <CapitalG />,
		description: 'Capital G with spur',
		notes: 'Use always',
	},
	{
		preview: <SingleStoryA />,
		description: 'Single-story a',
		notes: 'Use always',
	},
	{
		preview: <FlatTop3 />,
		description: 'Flat-top 3',
		notes: 'Use always',
	},
	{
		preview: <AlternateGermanDoubleS />,
		description: 'Alternate German double s',
		notes: 'Use always',
	},
	{
		preview: <SquarePunctuationQuotes />,
		description: 'Square punctuation and square quotes',
		notes: 'Use always',
	},
	{
		preview: <SlashedZero />,
		description: 'Slashed zero',
		notes: 'To be used selectively with data points',
	},
	{
		preview: <TabularFigures />,
		description: 'Tabular figures',
		notes: 'For use in tables and lists',
	},
];

const fontFeaturesMapMono: FeatureValue[] = [
	{
		preview: <MonoSlashedZero />,
		description: 'Slashed zero',
		notes: 'Use always',
	},
	{
		preview: <MonoLigatures />,
		description: 'Ligatures',
		notes: 'Do not use',
	},
];

const FontFeaturesTableBase = ({ values }: { values: FeatureValue[] }) => {
	return (
		<HorizontalScrollContainer>
			<Table>
				<THead>
					<TR isBodyRow={false}>
						<TH width="120px">Preview</TH>
						<TH width="340px">Description</TH>
						<TH width="340px">Notes</TH>
					</TR>
				</THead>
				<TBody>
					{values.map((feature) => {
						return (
							<TR key={feature.description}>
								<TD>{feature.preview}</TD>
								<TD>{feature.description}</TD>
								<TD>{feature.notes}</TD>
							</TR>
						);
					})}
				</TBody>
			</Table>
		</HorizontalScrollContainer>
	);
};

/**
 * __FontFeaturesTableSans__
 */
export const FontFeaturesTableSans = () => {
	return <FontFeaturesTableBase values={fontFeaturesMapSans} />;
};

/**
 * __FontFeaturesTableMono__
 */
export const FontFeaturesTableMono = () => {
	return <FontFeaturesTableBase values={fontFeaturesMapMono} />;
};
