export const EMPTY_TITLE_PLACEHOLDER = 'NO TITLE';

export const LOCAL_STORAGE_THEME_KEY = 'currentTheme';
export const LOCAL_STORAGE_TYPOGRAPHY_THEME_KEY = 'currentTypographyTheme';

/**
 * The GridItem span we use for pages like Resources, Foundations, etc.
 */
export const TYPICAL_CARD_SPAN = { xs: 6 } as const;
/**
 * The GridItem span we use for components explicitly (it's got a lot more items)
 */
export const COMPONENTS_CARD_SPAN = { xs: 6, sm: 4 } as const;
